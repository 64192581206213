// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { Container, NavDropdown, Tab, Form } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import "./Notifications.css";
import drop from "../../assest/images/notification/drop.svg";
import fav from "../../assest/images/notification/star (1).svg";
import refreshh from "../../assest/images/notification/refresh.svg";
import cl from "../../assest/images/notification/cl.svg";
import cr from "../../assest/images/notification/cr.svg";
import delet from "../../assest/images/notification/delet.svg";
import cw from "../../assest/images/notification/cw.svg";
import read from "../../assest/images/notification/read.svg";
import whitestar from "../../assest/images/notification/whitestar.svg";
import Footer from "../../layouts/Footer/Footer";
import ads from "../../assest/images/notification/Ads.svg";
import bookmark from "../../assest/images/notification/bookmark.svg";
import message from "../../assest/images/notification/message-square.svg";
import subscription from "../../assest/images/notification/subscription.svg";
import user from "../../assest/images/notification/user.svg";
import users from "../../assest/images/notification/users.svg";
import Down from "./Down.svg";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";

export const Notifications = () => {
  // State variables
  const [notifications, setNotifications] = useState([]);
  const { authInfo } = useContext(AuthContext);
  const [trashed, setTrashed] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllTrash, setSelectAllTrash] = useState(false);
  const [count, setCount] = useState(0);
  const [isChecked, setisChecked] = useState([]);
  const [isCheckedRestore, setisCheckedRestore] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    fetchNotif();
  };
  // Function to refresh the page
  const refresh = () => window.location.reload(true);
  const navigate = useNavigate();
  // Function to handle selecting individual notifications
  const handleSelected = (e, id) => {
    const newChecked = [...isChecked];
    if (e.target.checked) {
      newChecked.push(id); // Add id to the array if the checkbox is checked
    } else {
      const index = newChecked.indexOf(id);
      if (index !== -1) {
        newChecked.splice(index, 1); // Remove id from the array if the checkbox is unchecked
      }
    }
    setisChecked(newChecked);
    setSelectAll(newChecked.length === notifications.length);
  };

  // Function to handle selecting individual notifications in trash
  const handleSelectedRestore = (e, id) => {
    const newChecked = [...isCheckedRestore];
    if (e.target.checked) {
      newChecked.push(id); // Add id to the array if the checkbox is checked
    } else {
      const index = newChecked.indexOf(id);
      if (index !== -1) {
        newChecked.splice(index, 1); // Remove id from the array if the checkbox is unchecked
      }
    }
    setisCheckedRestore(newChecked);
    setSelectAllTrash(newChecked.length === trashed.length);
  };

  // Function to handle selecting all notifications
  const handleSelectedAll = () => {
    if (selectAll) {
      setisChecked([]); // When all are selected, unselect all
    } else {
      const allIDs = notifications.map((noti) => noti.id);
      setisChecked(allIDs);
    }
    setSelectAll(!selectAll); // Toggle the select all state
  };

  // Function to handle selecting all notifications in trash
  const handleSelectedAllTrash = () => {
    if (selectAllTrash) {
      setisCheckedRestore([]); // When all are selected, unselect all
    } else {
      const allIDs = trashed.map((noti) => noti.id);
      setisCheckedRestore(allIDs);
    }
    setSelectAllTrash(!selectAllTrash); // Toggle the select all state
  };

  // Function to fetch notifications
  const fetchNotif = async () => {
    try {
      const response = await axios.get(
        `https://api.whitesooq.com/api/v1/notifications/get-${selectedOption}`,
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.notifications);
      setNotifications(response.data.notifications);
      setCount(response.data.count);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to fetch trashed notifications
  const fetchTrach = async () => {
    try {
      const response = await axios.get(
        "https://api.whitesooq.com/api/v1/notifications/trashed",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      console.log(response.data);
      setTrashed(response.data.notifications);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle trashing notifications
  const handleTrach = async () => {
    try {
      const response = await axios.delete(
        `https://api.whitesooq.com/api/v1/notifications/delete`,
        {
          data: { ids: isChecked },
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      setSelectAll(false);
      setisChecked([]);
      // After deleting, fetch notifications and trashed notifications again
      fetchNotif();
      fetchTrach();
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle force-deleting notifications
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `https://api.whitesooq.com/api/v1/notifications/force-delete`,
        {
          data: { ids: isCheckedRestore },
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      setisCheckedRestore([]);
      setSelectAllTrash(false);
      // After deleting, fetch notifications and trashed notifications again
      fetchNotif();
      fetchTrach();
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle restoring trashed notifications
  const handleRestore = async () => {
    try {
      const response = await axios.put(
        `https://api.whitesooq.com/api/v1/notifications/restore`,
        { ids: isCheckedRestore },
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      setisCheckedRestore([]);
      selectAllTrash(false);
      // After restoring, fetch notifications and trashed notifications again
      fetchNotif();
      fetchTrach();
    } catch (err) {
      console.log(err);
    }
  };

  // Function to mark notifications as read
  const markRead = async () => {
    try {
      const response = await axios.put(
        `https://api.whitesooq.com/api/v1/notifications/mark-as-read`,
        { ids: isChecked },
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setisChecked([]);
      setSelectAll(false);
      console.log(response);
      // After marking as read, fetch notifications and trashed notifications again
      fetchNotif();
      fetchTrach();
    } catch (err) {
      console.log(err);
    }
  };

  // Function to mark notifications as unread
  const markUnRead = async () => {
    try {
      const response = await axios.put(
        `https://api.whitesooq.com/api/v1/notifications/mark-as-unread`,
        { ids: isChecked },
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      setisChecked([]);
      setSelectAll(false);
      // After marking as unread, fetch notifications and trashed notifications again
      fetchNotif();
      fetchTrach();
    } catch (err) {
      console.log(err);
    }
  };
  // Fetch notifications and trashed notifications on initial component load
  useEffect(() => {
    fetchNotif();
    fetchTrach();
  }, []);

  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_963_509)">
        <path
          d="M18 6L6 18"
          stroke="#1D3557"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="#1D3557"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_963_509">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div>
      <div className="notification">
        <Container>
          <Link style={{ direction: "rtl" }} className="iconcloooo" to="/">
            {closeIcon}
          </Link>
          <Tabs className="">
            {/* Tab for deleted notifications */}
            <Tab
              eventKey="first"
              title="الإشعارات المحذوفة"
              className="tabb"
              dir="rtl"
            >
              <div className="tabtop d-flex">
                {/* Checkbox to select all trashed notifications */}

                <div className="d-flex">
                  <input
                    className="check-boox"
                    style={{
                      marginTop: "5px",
                    }}
                    type="checkbox"
                    checked={selectAllTrash}
                    onChange={handleSelectedAllTrash}
                  />
                  <Form.Select
                    aria-label="Default select example"
                    style={{
                      width: "1px",
                      height: "2px",
                      borderColor: "white",
                      marginTop: "14px",
                      marginRight: "10px",
                    }}
                    value={selectedOption}
                    onChange={(e) => handleOptionSelect(e.target.value)}
                  >
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="all"
                      active={selectedOption === "all"}
                    >
                      كل
                    </option>
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="read"
                      active={selectedOption === "read"}
                    >
                      مقروء
                    </option>
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="unread"
                      active={selectedOption === "unread"}
                    >
                      غير مقروء
                    </option>
                  </Form.Select>
                </div>

                {/* Button to permanently delete selected notifications */}
                <button className="btnnoti" onClick={handleDelete}>
                  <img style={{ marginLeft: "8px" }} src={delet} /> حذف نهائي
                </button>
                {/* Button to restore selected notifications */}
                <button className="btnnoti" onClick={handleRestore}>
                  <img style={{ marginLeft: "8px" }} src={cw} /> إعادة تخزين
                </button>
                {/* <div className="iconspan d-flex me-auto">
                  <button>
                    <img src={cr} />
                  </button>
                  <button>
                    <img src={cl} />
                  </button>
          </div>*/}
              </div>
              {/* Deleted notifications display */}
              {trashed.map((noti, index) => {
                return (
                  <div
                    className={`tabdiv d-flex ${
                      noti.read_at ? "readeed" : "un-readed"
                    }`}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="tabdivData">
                        <input
                          className="check-boox marr"
                          type="checkbox"
                          id={noti.id}
                          checked={
                            selectAllTrash || isCheckedRestore.includes(noti.id)
                          }
                          onChange={(e) => handleSelectedRestore(e, noti.id)}
                        />
                        <div
                          onClick={() =>
                            navigate(`/notifications-view-details/${noti.id}`)
                          }
                        >
                          <p className="notificat-p back-curr">
                            {noti.data.body}
                          </p>
                        </div>
                      </div>
                      <div className="me-auto">
                        <img
                          src={
                            noti.data.from === "نظام التعليقات"
                              ? message
                              : noti.data.from === "نظام المسؤولين"
                              ? user
                              : noti.data.from === "نظام المستخدمين"
                              ? users
                              : noti.data.from === "نظام الإعلانات"
                              ? ads
                              : noti.data.from === "نظام الإشتراكات"
                              ? subscription
                              : noti.data.from === "نظام العروض"
                              ? bookmark
                              : ""
                          }
                          className="favv"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Tab>

            {/* Tab for incoming notifications */}
            <Tab
              eventKey="second"
              title="الإشعارات الواردة"
              className="tabb"
              dir="rtl"
            >
              <div className="tabtop d-flex">
                <div className="d-flex">
                  <input
                    className="check-boox"
                    style={{
                      marginTop: "5px",
                    }}
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectedAll}
                  />
                  <Form.Select
                    aria-label="Default select example"
                    style={{
                      width: "1px",
                      height: "2px",
                      borderColor: "white",
                      marginTop: "14px",
                      marginRight: "10px",
                    }}
                    value={selectedOption}
                    onChange={(e) => handleOptionSelect(e.target.value)}
                  >
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="all"
                      active={selectedOption === "all"}
                    >
                      كل
                    </option>
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="read"
                      active={selectedOption === "read"}
                    >
                      مقروء
                    </option>
                    <option
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      value="unread"
                      active={selectedOption === "unread"}
                    >
                      غير مقروء
                    </option>
                  </Form.Select>
                </div>
                {/* Button to refresh notifications */}
                <button onClick={refresh} className="mr">
                  <img style={{ marginLeft: "15px" }} src={refreshh} />
                </button>
                {/* Button to move selected notifications to trash */}
                <button
                  onClick={handleTrach}
                  className="btnnoti"
                  style={{ width: "105px" }}
                >
                  <img src={delet} /> حذف
                </button>
                {/* Button to mark selected notifications as unread */}
                <button
                  className="btnnoti"
                  onClick={markUnRead}
                  style={{ width: "133px" }}
                >
                  <img src={whitestar} /> غير مقروء
                </button>
                {/* Button to mark selected notifications as read */}
                <button
                  className="btnnoti"
                  onClick={markRead}
                  style={{ width: "105px" }}
                >
                  <img src={read} /> مقروء
                </button>
                {/* <div className="iconspan d-flex me-auto">
                  <button>
                    <img src={cr} />
                  </button>
                  <button>
                    <img src={cl} />
                  </button>
    </div>*/}
              </div>
              {/* Incoming notifications display */}
              {notifications.map((noti, index) => {
                return (
                  <div
                    className={`tabdiv d-flex ${
                      noti.read_at == null ? "un-readed" : "readeed"
                    }`}
                  >
                    <span className={`tabdivData`}>
                      <input
                        className="check-boox marr"
                        type="checkbox"
                        id={noti.id}
                        checked={selectAll || isChecked.includes(noti.id)}
                        onChange={(e) => handleSelected(e, noti.id)}
                      />

                      <div
                        onClick={() =>
                          navigate(`/notifications-view-details/${noti.id}`)
                        }
                      >
                        <p className="back-curr">{noti.data.body}</p>
                      </div>
                    </span>
                    <span className="me-auto">
                      <img
                        src={
                          noti.data.from === "نظام التعليقات"
                            ? message
                            : noti.data.from === "نظام المسؤولين"
                            ? user
                            : noti.data.from === "نظام المستخدمين"
                            ? users
                            : noti.data.from === "نظام الإعلانات"
                            ? ads
                            : noti.data.from === "نظام الإشتراكات"
                            ? subscription
                            : noti.data.from === "نظام العروض"
                            ? bookmark
                            : ""
                        }
                        className="favv"
                      />
                    </span>
                  </div>
                );
              })}
            </Tab>
          </Tabs>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
