import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
// @ts-ignore
import classes from "./EditSaleADForm.module.css";
import { Button, Typography, IconButton } from "@mui/material";
import ButtonAD from "../../User/Ads/Button/ButtonAD";
import { useDropzone } from "react-dropzone";
// @ts-ignore
import { useState, useEffect, useContext } from "react";
import ModalImage from "./ModalImage/ModalImage";
import { Modal } from "@mui/material";
import {
  CloudUpload,
  Upgrade,
  Clear,
  InsertDriveFile,
  AddBox,
  DeleteForeverRounded,
} from "@mui/icons-material";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import NestedList from "components/Utilities/NestedList";
import Snack from "components/Utilities/Snack";
const EditSaleADForm = () => {
  const [category, setCategory] = useState({
    name: "الفئات",
    id: "",
    oldId: "",
  });
  const handleCategory = (newCategory) => {
    setCategory(newCategory);
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  // @ts-ignore
  const [data, setData] = useState({});
  const [nameAD, setNameAD] = useState("");
  // @ts-ignore
  const [detilsAD, setDetailAD] = useState("");
  const [price, setPrice] = useState("");
  const [semanticWordsAD, setSementicWordsAD] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [urls, setURLs] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  // console.log(data);
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  // console.log("files", files);
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    // @ts-ignore
    return new File([data], Math.random(), metadata);
  };
  // convertURLtoFile(
  //     "https://via.placeholder.com/640x480.png/006688?text=amet "
  // ).then((val) => console.log(val));
  useEffect(() => {
    const getADDetails = async () => {
      try {
        // @ts-ignore
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/ads/${id}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        // console.log(response);
        if (response?.data?.data) {
          if (
            response.data.data.type !== "sale" ||
            response.data.data === null
          ) {
            navigate("/");
          } else {
            setData(response.data.data);
            setNameAD(response.data.data.title);
            setDetailAD(response.data.data.description);
            setPrice(response.data.data.price);
            setCategory({
              name: category.name,
              id: category.id,
              oldId: response.data.data.category_id,
            });
            setURLs(response.data.data.all_images);
            var tagNames = response.data.data.tags.map(function (tag) {
              return tag.name;
            });
            // console.log(tagNames);
            setSementicWordsAD(tagNames.join("،"));
          }
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          // @ts-ignore
          navigate("/");
        }
        // console.log(err);
        navigate("/");
      }
    };
    getADDetails();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };
  // console.log(files);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const handleFileSelect = (event) => {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // console.log(formData);
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (files.length !== 0 || urls.length !== data?.all_images?.length) {
      urls.forEach(async (url) => {
        const response = await convertURLtoFile(url);
        // console.log(response);
        // await formData.append("images[]", response);
      });
      files?.forEach((file) => {
        formData.append("images[]", file);
      });
    }
    // @ts-ignore
    const tags = semanticWordsAD.split("،");
    // @ts-ignore
    formData.append("category_id", category.id);
    formData.append("title", nameAD);
    formData.append("description", detilsAD);
    formData.append("price", price);
    tags.forEach((tag) => {
      formData.append("tags", tag);
    });

    // console.log("tags", formData.getAll("tags"));
    // console.log(Object.fromEntries(formData));
    setTimeout(async () => {
      try {
        const response = await axios.post(
          `https://api.whitesooq.com/api/v1/ads/${id}`,
          formData,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        // console.log(response);
        if (response?.data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ التعديل بنجاح",
          });
        } else if (response?.data?.status === 0) {
          // console.log(1);
          setAlert({
            open: true,
            type: "warning",
            message: response?.data?.message,
          });
        }
      } catch (err) {
        if (Error.request.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else if (err?.response?.status === 401) {
          ChangeAuthInfo({});
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
          navigate("/");
        } else if (err?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: err?.response?.data?.message,
          });
        } else if (err?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "الخادم لا يستجيب",
          });
        }
      }
    }, 1000);
  };
  // console.log(selectedImage);
  const deleteImage = (index) => {
    const updatedImages = files.filter((_, i) => i !== index);
    setFiles(updatedImages);
    setSelectedImage(null);
  };
  const deleteURL = (index) => {
    const updatedImages = urls.filter((_, i) => i !== index);
    setURLs(updatedImages);
    setSelectedImage(null);
  };
  const enlargeImage = (index) => {
    setSelectedImage(index);
  };
  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  return (
    <>
      <Snack alert={alert} />
      <Box className={classes.NewAd}>
        <form onSubmit={submitHandler}>
          {/* <!--Start Name AD--> */}
          <div className={classes.control}>
            <label htmlFor="name_ad">
              اسم الإعلان<span> *</span>
            </label>
            <input
              className={classes.Input}
              type="text"
              id="name_ad"
              value={nameAD}
              required
              onChange={(e) => setNameAD(e.target.value)}
              placeholder="ساعة يد بيضاء"
            />
          </div>
          {/* <!--End Name AD--> */}
          {/* <!--Start Details AD--> */}
          <div className={classes.control}>
            <label htmlFor="details_ad">
              تفاصيل الإعلان<span> *</span>
            </label>
            <textarea
              id="details_ad"
              className={classes.Input}
              // @ts-ignore
              rows="3"
              required
              value={detilsAD}
              onChange={(e) => setDetailAD(e.target.value)}
              placeholder="للبيع ساعة يد بيضاء مع غطاء بلاستيك مع وجود خدش صغيرة في الجانب الايسر للساعة للبيع ساعة يد بيضاء مع غطاء بلاستيك للبيع ساعة يد بيضاء مع غطاء بلاستيك للبيع ساعة يد بيضاء مع غطاء بلاستيك"
            ></textarea>
          </div>
          {/* <!--End Details AD--> */}
          {/* <!--Start Categories AD--> */}
          <div className={classes.control}>
            <label htmlFor="Categories_ad">
              اختيار فئات الإعلان<span> *</span>
            </label>
            <NestedList category={category} handleCategory={handleCategory} />
          </div>
          {/* <!--End Categories AD--> */}
          {/* <!--Start Prices AD--> */}
          <div className={classes.control}>
            <label htmlFor="price_ad">السعر</label>
            <input
              className={classes.Input}
              type="text"
              id="price_ad"
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          {/* <!--End Prices AD--> */}
          {/* <!--Start Semantic words AD--> */}
          <div className={classes.control}>
            <label htmlFor="name_ad">
              كلمات دلالية للإعلان<span> *</span>
            </label>
            <input
              className={classes.Input}
              type="text"
              id="semantic_words_ad"
              required
              value={semanticWordsAD}
              // @ts-ignore
              onChange={(e) => setSementicWordsAD(e.target.value)}
              placeholder="الكترونيات, العاب, بلاي ستيشن, أجهزة الكترونيات, مستعمل, قطع الكترونية"
            />
          </div>
          {/* <!--End Semantic words AD--> */}
          {/* <!--Start Button ADD Images--> */}
          <Box>
            <div className={classes.control}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "space-between",
                  },
                  direction: "ltr",
                }}
                item
                xs={8}
              >
                <ButtonAD title={"تحديث"} />
                <Button
                  sx={{
                    color: "#1D3557",
                    fontSize: "18px",
                    fontWeight: 700,
                    border: "1px solid #457b9d",
                    px: "0 5px",
                    mr: 1,
                    borderRadius: "8px",
                    direction: "ltr",
                  }}
                  onClick={handleOpen}
                  startIcon={<CloudUpload />}
                  variant="outlined"
                >
                  إضافة ألبوم الصور
                </Button>
              </Grid>
            </div>
            <div className="file-list">
              <div className={classes.divImages}>
                {urls?.map((file, index) => (
                  <div key={index} className={classes.imageContainer}>
                    <img src={file} alt={file.name} />
                    <div className={classes.buttonContainer}>
                      {/*   <Button
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    "&:hover": {
                                                        borderRadius: "100%",
                                                        p: 0,
                                                    },
                                                }}
                                                onClick={() =>
                                                    enlargeImage(index)
                                                }
                                            >
                                                <VisibilityRounded />
                                            </Button>*/}
                      <Button
                        sx={{
                          p: 0,
                          m: 0,
                          "&:hover": {
                            borderRadius: "100%",
                            p: 0,
                          },
                        }}
                        onClick={() => deleteURL(index)}
                      >
                        <DeleteForeverRounded />
                      </Button>
                    </div>
                  </div>
                ))}
                {selectedImage !== null && (
                  <ModalImage
                    image={URL.createObjectURL(files[selectedImage])}
                    onClose={handleCloseModal}
                    newClass={classes.modalImage}
                  />
                )}
              </div>
            </div>
            <div className="file-list">
              <div className={classes.divImages}>
                {files?.map((file, index) => (
                  <div key={index} className={classes.imageContainer}>
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                    <div className={classes.buttonContainer}>
                      {/*   <Button
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    "&:hover": {
                                                        borderRadius: "100%",
                                                        p: 0,
                                                    },
                                                }}
                                                onClick={() =>
                                                    enlargeImage(index)
                                                }
                                            >
                                                <VisibilityRounded />
                                            </Button>*/}
                      <Button
                        sx={{
                          p: 0,
                          m: 0,
                          "&:hover": {
                            borderRadius: "100%",
                            p: 0,
                          },
                        }}
                        // onClick={deleteImage}
                        onClick={() => deleteImage(index)}
                      >
                        <DeleteForeverRounded />
                      </Button>
                    </div>
                  </div>
                ))}
                {selectedImage !== null && (
                  <ModalImage
                    image={URL.createObjectURL(files[selectedImage])}
                    onClose={handleCloseModal}
                    newClass={classes.modalImage}
                  />
                )}
              </div>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  xs: { width: 400, height: 300 },
                }}
              >
                {/* button close popup*/}
                <Button
                  sx={{
                    position: "absolute",
                    right: 0,
                    m: 0,
                  }}
                  onClick={handleClose}
                >
                  <Clear />
                </Button>
                {/* popup*/}
                <Typography
                  sx={{
                    width: "100%",
                    bgcolor: "#C5BFBF",
                    height: "80%",
                    mt: 5,
                    borderRadius: 10,
                    textAlign: "center",
                  }}
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? "active" : ""}`}
                >
                  <input onChange={handleFileSelect} {...getInputProps()} />
                  {isDragActive ? (
                    <p>قم بإفلات الملفات هنا...</p>
                  ) : (
                    <div className={classes.AddImge}>
                      <p>...أدرج الصور هنا</p>
                      <IconButton>
                        <AddBox />
                      </IconButton>
                    </div>
                  )}
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    mt: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={{
                      width: "28%",
                      bgcolor: "#1d3557",
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: 600,
                      "&:hover": { color: "#1d3557" },
                    }}
                    startIcon={<InsertDriveFile />}
                    variant="outlined"
                  >
                    <Upgrade />
                    المتصفح
                    <input
                      type="file"
                      className={classes.btnImages}
                      multiple
                      onChange={handleFileSelect}
                    />
                  </Button>
                  <input
                    className={classes.text}
                    placeholder="اختر الملف"
                    type="text"
                  />
                </Typography>
              </Box>
            </Modal>
          </Box>
        </form>
      </Box>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
  border: "1px solid #457b9d",
  px: 4,
  pb: 3,
};
export default EditSaleADForm;
