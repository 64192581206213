import { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Image,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
// @ts-ignore
import Home from "./../AddCategory/Home.svg";
// @ts-ignore
import Arrow from "./../AddCategory/Arrow.svg";
import Services from "../../../Files/Services.json";
import Categories from "../../../Files/Categories.json";
// @ts-ignore
import Upload from "./../AddCategory/Upload.svg";
// @ts-ignore
import Eye from "./../AddCategory/Eye.svg";
// @ts-ignore
import Delete from "./../AddCategory/Delete.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import NestedList from "components/Utilities/NestedList";
import Snack from "components/Utilities/Snack";
import "./EditServices.css";
import { IconButton } from "@mui/material";
import { East } from "@mui/icons-material";

const EditServices = () => {
  const [category, setCategory] = useState({
    name: "الفئات",
    id: "",
    oldId: "",
    idHidden: "",
    category: "category",
  });
  const [update, setUpdate] = useState(false);
  const handleCategory = (newCategory) => {
    setCategory(newCategory);
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  // @ts-ignore
  const { id } = useParams();
  const params = useParams();
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  // @ts-ignore
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState([]);
  const [url, setURL] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // console.log(selectedImage);
  // console.log(image);
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImage(uploadedImages);
    setSelectedFile(e.target.files[0]);
  };
  const handleDeleteImage = (index) => {
    setImage((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };
  const photoHuge = (photo) => {
    setSelectedImage(photo);
    setShowModal(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(image);
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("description", categoryDescription);
    if (category.name !== "أساسية" || category.id !== "")
      formData.append("parent_id", category.id);
    // if (category.name === "أساسية" || category.id === "")
    //     formData.append("parent_id", null);
    formData.append("status", status);
    // @ts-ignore
    if (selectedFile !== null) formData.append("image", selectedFile);
    // @ts-ignore
    // console.log("FormData:", Object.fromEntries(formData));
    try {
      const response = await axios.post(
        `https://api.whitesooq.com/api/v1/categories/${id}`,
        formData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      // console.log(response);
      if (response?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: "تمَّ التعديل بنجاح",
        });
        setUpdate(!update);
      } else if (response?.data?.status === 0) {
        // console.log(1);
        setAlert({
          open: true,
          type: "warning",
          message: response?.data?.message,
        });
      }
    } catch (err) {
      if (err.response.status === 403) {
        setAlert({
          open: true,
          type: "warning",
          message: "عذراً ليس لديك صلاحية",
        });
      } else if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
        navigate("/");
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "الخادم لا يستجيب",
        });
      }
      // console.log(err);
    }
  };
  useEffect(() => {
    const getCategoryDetails = async () => {
      try {
        // @ts-ignore
        const response = await axios.get(
          // @ts-ignore
          `https://api.whitesooq.com/api/v1/categories/${id}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        // console.log(response);
        if (response?.data?.data) {
          setCategoryName(response.data.data[0].name);
          setCategoryDescription(response.data.data[0].description);
          setStatus(response.data.data[0].status);
          setURL(response.data.data[0].category_image);
          setCategory({
            name: response.data.data[0].type === "اساسية" ? "أساسية" : "",
            id: category.id,
            oldId:
              response.data.data[0].parent_id === null
                ? ""
                : response.data.data[0].parent_id,
            idHidden: response.data.data[0].id,
            category: category.category,
          });
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          navigate("/");
        }
        // console.log(err);
      }
    };
    getCategoryDetails();
  }, [update]);
  return (
    <>
      <Snack alert={alert} />
      <div
        className="ediittt"
        style={{ display: "grid", backgroundColor: "#ededed" }}
      >
        <Form.Label
          style={{
            display: "flex",
            direction: "rtl",
            alignItems: "flex-start",
            marginRight: "10px",
            marginTop: "16px",
          }}
        >
          <Image style={{ marginTop: "-2px" }} src={Home} />
          <h4
            style={{
              color: "#1D3557",
              fontSize: "18px",
              marginRight: "5px",
            }}
          >
            {params.type === "deleted" ? (
              <Link
                to={"/DeletedData-page"}
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginRight: "5px",
                  fontFamily: "inherit",
                }}
              >
                الفئات المحذوفة
              </Link>
            ) : (
              <Link
                to={"/nonDeletedData-page"}
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginRight: "5px",
                  fontFamily: "inherit",
                }}
              >
                الفئات الغير المحذوفة
              </Link>
            )}
            / {categoryName}
          </h4>
        </Form.Label>
        <div
          style={{
            display: "grid",
            placeItems: "center",
            marginRight: "17px",
            marginLeft: "93px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <Form
            dir="rtl"
            style={{
              width: "95%",
              backgroundColor: "#FFFFFF",
              padding: "32px",
              borderRadius: "8px",
              boxShadow: "0px 4px 25px 0px #41404033",
              marginTop: "73px",
            }}
          >
            {params.type === "deleted" ? (
              <div style={{ direction: "rtl" }}>
                <IconButton
                  sx={{ m: 1 }}
                  onClick={() =>
                    navigate(`/category-services-page/${id}/deleted`)
                  }
                >
                  <East />
                </IconButton>
              </div>
            ) : (
              <div style={{ direction: "rtl" }}>
                <IconButton
                  sx={{ m: 1 }}
                  onClick={() =>
                    navigate(`/category-services-page/${id}/nondeleted`)
                  }
                >
                  <East />
                </IconButton>
              </div>
            )}
            <Col>
              <h2
                style={{
                  color: "#1D3557",
                  fontSize: "35px",
                  marginTop: "16px",
                }}
              >
                تعديل فئة {categoryName}
              </h2>
            </Col>
            <Form.Group controlId="categoryName">
              <Form.Label
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginTop: "16px",
                }}
              >
                اسم الفئة <i style={{ color: "red" }}>*</i>
              </Form.Label>
              <Form.Control
                type="text"
                value={categoryName}
                placeholder="خدمات"
                onChange={(e) => setCategoryName(e.target.value)}
                required
                style={{ border: "1px solid #457B9D" }}
              />
            </Form.Group>

            <Form.Group controlId="categoryDescription">
              <Form.Label
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                وصف الفئة <i style={{ color: "red" }}>*</i>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={categoryDescription}
                placeholder="فئة خدمية تساعد المستخدمين"
                onChange={(e) => setCategoryDescription(e.target.value)}
                required
                style={{ border: "1px solid #457B9D" }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="serviceType">
              <Form.Label
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                {" "}
                نوع الفئة <i style={{ color: "red" }}>*</i>
              </Form.Label>

              <NestedList category={category} handleCategory={handleCategory} />
            </Form.Group>
            <Form.Group as={Col} controlId="categoryStatus">
              <Form.Label style={{ color: "#1D3557", fontSize: "18px" }}>
                {" "}
                حالة الفئة <i style={{ color: "red" }}>*</i>
              </Form.Label>
              <Form.Select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
                style={{
                  border: "1px solid #457B9D",
                  height: "57px",
                  borderRadius: "9px",
                  marginBottom: "10px",
                  fontSize: "15px",
                }}
              >
                <option value="active">فعالة</option>
                <option value="inactive">غير فعالة</option>
                <option value="archived">المؤرشفة</option>
                {status === "" && (
                  <p
                    style={{
                      color: "#E63946",
                      fontSize: "10px",
                      marginTop: "0",
                    }}
                  >
                    الرجاء الاختيار{" "}
                  </p>
                )}
              </Form.Select>
            </Form.Group>
            <Container>
              <Row>
                <Form.Group>
                  <Form.Label
                    style={{
                      width: "237px",
                      height: "54px",
                      borderRadius: "10px",
                      padding: "10px 32px",
                      border: "1px solid #457B9D",
                      marginRight: "-12px",
                      marginTop: "16px",
                      backgroundColor: "#FFFFFF",
                      color: "#1D3557",
                      fontSize: "18px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        color: "#1D3557",
                        fontSize: "18px",
                        fontWeight: "700",
                        marginLeft: "10px",
                      }}
                    >
                      إضافة ألبوم صور{" "}
                    </span>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                    <Image src={Upload} />
                  </Form.Label>
                </Form.Group>
              </Row>
              <Row>
                {image.map((photo, index) => (
                  <Col key={index} xs={6} md={3}>
                    <Card
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px",
                        border: "1px solid white",
                        marginRight: "-16px",
                      }}
                    >
                      <Card.Img
                        onClick={() => photoHuge(photo)}
                        src={photo.preview}
                        alt={photo.name}
                        style={{ height: "140px" }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Button
                          style={{
                            marginRight: "-3px",
                            backgroundColor: "white",
                            border: "none",
                          }}
                          onClick={() => handleDeleteImage(index)}
                        >
                          <Image
                            src={Delete}
                            alt="Delete"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Button>
                        {/* <Button
                                                    style={{
                                                        marginRight: "3px",
                                                        backgroundColor:
                                                            "white",
                                                        border: "none",
                                                    }}
                                                    onClick={() =>
                                                        photoHuge(index)
                                                    }
                                                >
                                                    <Image
                                                        src={Eye}
                                                        alt="Preview"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Button> */}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                {selectedFile === null && (
                  <Col xs={6} md={3}>
                    <Card
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px",
                        border: "1px solid white",
                        marginRight: "-16px",
                      }}
                    >
                      <Card.Img
                        // onClick={() => photoHuge(photo)}
                        src={url}
                        alt={"img"}
                        style={{ height: "140px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Button
                          style={{
                            marginRight: "-3px",
                            backgroundColor: "white",
                            border: "none",
                          }}
                          // onClick={() =>
                          //     handleDeleteImage(index)
                          // }
                        >
                          <Image
                            src={Delete}
                            alt="Delete"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Button>
                        {/* <Button
                                                    style={{
                                                        marginRight: "3px",
                                                        backgroundColor:
                                                            "white",
                                                        border: "none",
                                                    }}
                                                    // onClick={() => photoHuge(index)}
                                                >
                                                    <Image
                                                        src={Eye}
                                                        alt="Preview"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Button> */}
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
              <Modal
                show={selectedImage}
                onHide={() => setShowModal(false)}
                centered
              >
                <Modal.Body className="d-flex flex-column align-items-center">
                  <Image
                    src={selectedImage}
                    style={{
                      width: "500px",
                      height: "500px",
                    }}
                  />

                  <Button
                    onClick={() => setShowModal(false)}
                    style={{ marginTop: "10px" }}
                  >
                    إغلاق
                  </Button>
                </Modal.Body>
              </Modal>
            </Container>
            <Col style={{ direction: "ltr" }}>
              <Button
                type="submit"
                onClick={handleSubmit}
                style={{
                  border: "2px solid #457B9D",
                  backgroundColor: "#457B9D",
                  height: "60px",
                  borderRadius: "10px",
                  marginTop: "16px",
                  marginBottom: "30px",
                  cursor: "pointer",
                  width: "290px",
                  boxShadow: "0px 4px 19px 0px #7793414D",
                }}
              >
                <Link
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    textDecoration: "none",
                  }}
                  to=""
                >
                  تحديث
                </Link>
              </Button>
            </Col>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditServices;
