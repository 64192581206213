import React, { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";
import classes from "./PaymentItem.module.css";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/Select";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { ExpandMore, MoreVert } from "@mui/icons-material";
import { Check, Close, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Home from "./Home.svg";
import {
  Alert,
  Form,
  Image,
  Modal,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { useNavigate, Link, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentItem = (props) => {
  const [age, setAge] = useState("");
  const params = useParams();
  const { authInfo } = useContext(AuthContext);
  const [payData, setPayData] = useState({});
  const [userData, setUserData] = useState({});
  const [adminData, setAdminData] = useState({});
  const [packageData, setPackageData] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(false);

  // Handle the selection change
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // Open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
    setAge("");
  };

  const [objectionDeleted, setObjectionDeleted] = useState(false);
  const [error, setError] = useState("");

  // Handle the delete action
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `https://api.whitesooq.com/api/v1/payments/${params.id}`,
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      console.log(response);
      setIsDeleted(true);
      setShowAlert(true);
      setMessage("تم حذف الدفعة بنجاح");
      handleClose();
    } catch (err) {
      console.log(err);
      setIsDeleted(false);
      setShowAlert(true);
      setMessage("لم يتم حذف الدفعة الرجاء المحاولة لاحقا ");
      handleClose();
    }
  };

  const sentence = [
    { field1: "عنوان 1", field2: "25" },
    { field1: "قيمة 1", field2: "قيمة 2" },
    { field1: "قيمة 4", field2: "قيمة 5" },
    { field1: "قيمة 7", field2: "قيمة 8" },
    { field1: "قيمة 10", field2: "قيمة 11" },
    { field1: "قيمة 13", field2: "قيمة 14" },
    { field1: "قيمة 13", field2: "قيمة 14" },
    { field1: "قيمة 13", field2: "قيمة 14" },
    { field1: "قيمة 13", field2: "قيمة 14" },
  ];

  useEffect(() => {
    const ShowPayment = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/payments/${params.id}`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        setPayData(response.data.data);
        setUserData(response.data.data.user);
        setAdminData(response.data.data.admin.user);
        setPackageData(response.data.data.package);
      } catch (err) {
        console.log(err);
      }
    };
    ShowPayment();
  }, []);
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <section className="changeAlert">
        {/* Display an alert with a message and an "OK" button */}
        <Modal
          show={showAlert}
          variant="none"
          style={{ direction: "rtl" }}
          centered
        >
          <ModalHeader>{message}</ModalHeader>
          <ModalFooter className="d-flex justify-content-start">
            <Button
              onClick={() => setShowAlert(false)}
              style={{
                backgroundColor: "#1D3557",
                marginRight: "180px",
              }}
            >
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={isDeleted ? "/" : `/paymentitem-page/${params.id}`}
              >
                موافق
              </Link>
            </Button>
          </ModalFooter>
        </Modal>
      </section>

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/paymentData-page"
          >
            الدفعات
          </Link>
          / دفعة 22
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            width: { xs: 330, sm: 650 },
            height: { xs: 550, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل الدفعة
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px", height: "auto" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: 2,
              width: { xs: 330, sm: 560 },
              height: { xs: 550, sm: 600 },
            }}
          >
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/paymentData-page")}
            >
              <East />
            </IconButton>
            <Box className={classes.menuage}>
              <FormControl sx={{ m: 1 }}>
                {/* Display a dropdown with a "Delete" option */}
                <Select
                  IconComponent={ExpandMore}
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  autoWidth
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem onClick={handleClickOpen} sx={{ pl: 6 }}>
                    حذف
                  </MenuItem>
                </Select>
              </FormControl>
              {/* Display a confirmation dialog for deletion */}
              {!objectionDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"هل أنت متأكد من حذف هذا الدفعة"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      لا يمكن استعادة الدفعة عند الحذف
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleDelete}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {/* Display an error dialog if there's an error */}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {error}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {/* Display a success dialog if deletion is successful */}
              {objectionDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"تم حذف الدفعة بنجاح"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{ color: "#1d3557", fontWeight: 800, fontSize: "25px" }}
            >
              عرض دفعة {payData?.id} {props.numbOfPayment}
            </Typography>
            <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
              <table className={classes.tableDetails}>
                <tbody className="">
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{payData?.id}</div>
                    <div className={classes.sentence2}>معرف الدفعة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <Typography>{adminData.username}</Typography>
                        <Avatar
                          sx={{ ml: 1 }}
                          src={adminData.full_path_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>أنشىء بواسطة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{payData?.user_id}</div>
                    <div className={classes.sentence2}>معرف المستخدم</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <Typography>{userData.username}</Typography>
                        <Avatar
                          sx={{
                            ml: 1,
                            /* width: "40px", */ height: "40px",
                            p: 0,
                          }}
                          src={userData.full_path_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>اسم المستخدم</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <Rating
                        className={classes.flexTable}
                        name="read-only"
                        value={userData.rating}
                        readOnly
                      />
                    </div>
                    <div className={classes.sentence2}>تقييم المستخدم</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{packageData.name}</div>
                    <div className={classes.sentence2}>اسم الباقة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{packageData.id}</div>
                    <div className={classes.sentence2}>معرف الباقة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{packageData.type}</div>
                    <div className={classes.sentence2}>نوع الباقة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}></div>
                    <div className={classes.sentence2}>المبلغ المدفوع</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}></div>
                    <div className={classes.sentence2}>العملة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>
                      {packageData.created_at}
                    </div>
                    <div className={classes.sentence2}>تاريخ الإضافة</div>
                  </div>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default PaymentItem;
