// @ts-nocheck
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import classes from "./PurchaseADForm.module.css";
import { useState, useRef, useContext } from "react";
import ButtonAD from "../Button/ButtonAD";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";
import NestedList from "components/Utilities/NestedList";
import axios from "axios";

const PurchaseADForm = () => {
  const { authInfo } = useContext(AuthContext);
  const [category, setCategory] = useState({
    name: "الفئات",
    id: "",
  });

  const handleCategory = (newCategory) => {
    setCategory(newCategory);
  };

  const [error, setError] = useState("");
  const [conection, setConection] = useState("");
  const nameADRef = useRef();
  const detilsADRef = useRef();
  const lowestPriceRef = useRef();
  const highestPriceRef = useRef();
  const semanticWordsRef = useRef();

  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  const submitHandler = (event) => {
    event.preventDefault();
    const enteredNameAd = nameADRef.current.value;
    const enteredDetailsAd = detilsADRef.current.value;
    const enteredLowestPrice = lowestPriceRef.current.value;
    const enteredHighestPrice = highestPriceRef.current.value;
    const enteredSemanticWords = semanticWordsRef.current.value;
    const enteredSemanticWordsSplit = enteredSemanticWords.split("،");

    const formData = new FormData();
    formData.append("type", "buy");
    formData.append("category_id", category.id);
    formData.append("title", enteredNameAd);
    formData.append("description", enteredDetailsAd);
    formData.append("min", enteredLowestPrice);
    formData.append("max", enteredHighestPrice);
    formData.append("phone_number", conection);
    enteredSemanticWordsSplit.forEach((tag) => {
      formData.append("tags", tag);
    });

    axios
      .post("https://api.whitesooq.com/api/v1/ads", formData, {
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response?.status) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ إضافة الإعلان بنجاح",
          });
        }
      })
      .catch((error) => {
        setError(error);
        console.log(error);
        setAlert({
          open: true,
          type: "warning",
          message: error.response?.data.message,
        });
      });
  };

  return (
    <Box className={classes.NewAd}>
      <Snack alert={alert} />
      <form onSubmit={submitHandler}>
        {/* <!--Start Name AD--> */}
        <div className={classes.control}>
          <label htmlFor="name_ad">
            اسم الإعلان<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="name_ad"
            required
            ref={nameADRef}
            placeholder="مثال : ساعة يد بيضاء"
          />
        </div>
        {/* <!--End Name AD--> */}
        {/* <!--Start Details AD--> */}
        <div className={classes.control}>
          <label htmlFor="details_ad">
            تفاصيل الإعلان<span> *</span>
          </label>
          <textarea
            id="details_ad"
            className={classes.Input}
            rows="2"
            required
            ref={detilsADRef}
            placeholder="مثال : للبيع ساعة يد كاسيو يكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية وجذابة لون برونزي جميل تصلح رجالي ونسائي"
          ></textarea>
        </div>
        {/* <!--End Details AD--> */}
        {/* <!--Start Categories AD--> */}
        <div className={classes.control}>
          <label htmlFor="Categories_ad">
            اختيار فئات الإعلان<span> *</span>
          </label>
          <NestedList category={category} handleCategory={handleCategory} />
        </div>
        {/* <!--End Categories AD--> */}
        {/* <!--Start Prices AD--> */}
        <div className={classes.control}>
          <Grid container spacing={6} columns={16}>
            <Grid item xs={8}>
              <div>
                <label htmlFor="highest_price">
                  السعر الأعلى<span> *</span>
                </label>
                <input
                  placeholder="50000"
                  className={classes.Input}
                  type="number"
                  id="highest_price"
                  required
                  ref={highestPriceRef}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                <label htmlFor="lowest_price">
                  السعر الأدنى<span> *</span>
                </label>
                <input
                  placeholder="10000"
                  className={classes.Input}
                  type="number"
                  id="lowest_price"
                  required
                  ref={lowestPriceRef}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <!--End Prices AD--> */}
        {/* <!--Start Semantic words AD--> */}
        <div className={classes.control}>
          <label htmlFor="semantic_ad">
            كلمات دلالية للإعلان<span> *</span>
          </label>
          <input
            placeholder="الكترونيات , هاردات , لابتوبات"
            className={classes.Input}
            type="text"
            id="semantic_ad"
            required
            ref={semanticWordsRef}
          />
        </div>
        {/* <!--End Semantic words AD--> */}
        {/* <!--Start ADD conection number--> */}
        <div className={classes.control}>
          <label htmlFor="conection">
            رقم التواصل <span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="conection"
            required
            value={conection}
            onChange={(e) => setConection(e.target.value)}
            placeholder="999886688 962+"
          />
        </div>
        {/* <!--End ADD conection number--> */}
        {/* <!--Start Button ADD AD--> */}
        <div className={classes.control}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
            item
            xs={8}
          >
            <ButtonAD title={"إضافة"} />
          </Grid>
        </div>
        {/* <!--End Button ADD AD--> */}
      </form>
    </Box>
  );
};

export default PurchaseADForm;
