// @ts-nocheck
import { useState, useRef, useContext } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import classes from "./SaleADForm.module.css";
import Modal from "@mui/material/Modal";
import ModalImage from "./../../../../Admin/EditSaleADForm/ModalImage/ModalImage";
import {
  CloudUpload,
  Upgrade,
  Clear,
  InsertDriveFile,
  AddBox,
  VisibilityRounded,
  DeleteForeverRounded,
} from "@mui/icons-material";
import { AuthContext } from "store/UseAuthContext";
import { useDropzone } from "react-dropzone";
import ButtonAD from "./../../Button/ButtonAD";
import Snack from "components/Utilities/Snack";
import NestedList from "components/Utilities/NestedList";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  height: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
  border: "1px solid #457b9d",
  px: 4,
  pb: 3,
};
const SaleADForm = () => {
  const [page, setPage] = useState(1);
  const nextPage = () => {
    setPage(page + 1);
  };
  const { authInfo } = useContext(AuthContext);
  const [category, setCategory] = useState({
    name: "الفئات",
    id: "",
  });
  const handleCategory = (newCategory) => {
    setCategory(newCategory);
  };

  const [error, setError] = useState("");
  const [nameAD, setNameAD] = useState("");
  const [detailsAD, setDetailsAD] = useState("");
  const [price, setPrice] = useState("");
  const [semanticWords, setSemanticWords] = useState("");
  const [conection, setConection] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const submitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("type", "sale");
    formData.append("category_id", category.id);
    formData.append("title", nameAD);
    formData.append("description", detailsAD);
    formData.append("price", price);
    formData.append("phone_number", conection);
    const enteredSemanticWordsSplit = semanticWords.split(" ، ");
    files?.forEach((file) => {
      formData.append("images[]", file);
    });
    enteredSemanticWordsSplit.forEach((tag) => {
      formData.append("tags", tag);
    });
    console.log(formData);
    try {
      const response = await axios.post(
        "https://api.whitesooq.com/api/v1/ads",
        formData,
        {
          headers: {
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            accept: "application/json",
          },
        }
      );
      console.log("response 1111" , response);

      if (response?.status === 200) {
        setAlert({
          open: true,
          type: "success",
          message: "تمَّ إضافة الإعلان بنجاح",
        });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        open: true,
        type: "warning",
        message:
          error.response?.data?.message || "حدث خطأ ما أثناء معالجة الطلب.",
      });
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [files, setFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };
  // console.log(files);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const handleFileSelect = (event) => {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    console.log("form", formData);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const deleteImage = (index) => {
    const updatedImages = files.filter((_, i) => i !== index);
    setFiles(updatedImages);
    setSelectedImage(null);
  };
  const enlargeImage = (index) => {
    setSelectedImage(index);
  };
  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  return (
    <Box className={classes.NewAd}>
      <Snack alert={alert} />
      <form /* onSubmit={submitHandler} */>
        {page === 1 && (
          <>
            <div className={classes.control}>
              <label htmlFor="name_ad">
                اسم الإعلان<span> *</span>
              </label>
              <input
                className={classes.Input}
                type="text"
                id="name_ad"
                required
                value={nameAD}
                onChange={(e) => setNameAD(e.target.value)}
                placeholder="مثال : ساعة يد بيضاء"
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="details_ad">
                تفاصيل الإعلان<span> *</span>
              </label>
              <textarea
                id="details_ad"
                className={classes.Input}
                // @ts-ignore
                rows="3"
                required
                value={detailsAD}
                onChange={(e) => setDetailsAD(e.target.value)}
                placeholder="مثال : للبيع ساعة يد كاسيو يكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية وجذابة لون برونزي جميل تصلح رجالي ونسائي"
              ></textarea>
            </div>
            <div className={classes.control}>
              <label htmlFor="Categories_ad">
                اختيار فئات الإعلان<span> *</span>
              </label>
              <NestedList category={category} handleCategory={handleCategory} />
            </div>
            <div className={classes.control}>
              <label htmlFor="price_ad">
                السعر<span> *</span>
              </label>
              <input
                className={classes.Input}
                type="number"
                id="name_ad"
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="10000"
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="semantic_ad">
                كلمات دلالية للإعلان<span> *</span>
              </label>
              <textarea
                rows="2"
                placeholder="الكترونيات، هاردات، لابتوبات"
                className={classes.Input}
                type="text"
                id="semantic_ad"
                required
                value={semanticWords}
                onChange={(e) => setSemanticWords(e.target.value)}
              ></textarea>
            </div>
            <div className={classes.control}>
              <label htmlFor="phone-number">
                رقم التواصل <span> *</span>
              </label>
              <input
                className={classes.Input}
                type="text"
                id="phone-number"
                required
                value={conection}
                onChange={(e) => setConection(e.target.value)}
                placeholder="999886688 962+"
              />
            </div>

            <div className={classes.control}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-start",
                  },
                }}
                item
                xs={8}
              >
                <div className={classes.btnNextPage} onClick={nextPage}>
                  التالي
                </div>
              </Grid>
            </div>
          </>
        )}
        {page === 2 && (
          <Box component="form">
            <Button
              sx={{
                color: "#1D3557",
                fontSize: "18px",
                fontWeight: 700,
                border: "1px solid #457b9d",
                px: "0 5px",
                mt: 2,
              }}
              onClick={handleOpen}
              startIcon={<CloudUpload sx={{ fill: "#1d3557" }} />}
              variant="outlined"
            >
              إضافة ألبوم الصور
            </Button>
            <div className="file-list">
              {files.length > 4 && (
                <p>عدد الصور يجب أن يكون بين الصفر والأربعة</p>
              )}
              <div className={classes.divImages}>
                {files.map((file, index) => (
                  <div key={index} className={classes.imageContainer}>
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                    <div className={classes.buttonContainer}>
                      <Button
                        sx={{
                          p: 0,
                          m: 0,
                          "&:hover": {
                            borderRadius: "100%",
                            p: 0,
                          },
                        }}
                        // onClick={deleteImage}
                        onClick={() => deleteImage(index)}
                      >
                        <DeleteForeverRounded />
                      </Button>
                    </div>
                  </div>
                ))}
                {selectedImage !== null && (
                  <ModalImage
                    image={URL.createObjectURL(files[selectedImage])}
                    onClose={handleCloseModal}
                    newClass={classes.modalImage}
                  />
                )}
              </div>
            </div>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              item
              xs={8}
            >
              {/* <ButtonAD onClick={submitHandler} title={"إضافة"} /> */}
              <button
                className={classes.btnAD}
                onClick={submitHandler}
                type="submit"
              >
                إضافة
              </button>
            </Grid>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  xs: { width: 400, height: 300 },
                }}
              >
                {/* button close popup*/}
                <Button
                  sx={{
                    position: "absolute",
                    right: 0,
                  }}
                  onClick={handleClose}
                >
                  <Clear />
                </Button>
                {/* popup*/}
                <Typography
                  sx={{
                    width: "100%",
                    bgcolor: "#C5BFBF",
                    height: "80%",
                    mt: 4,
                    borderRadius: 10,
                    textAlign: "center",
                  }}
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? "active" : ""}`}
                >
                  <input onChange={handleFileSelect} {...getInputProps()} />
                  {isDragActive ? (
                    <p>قم بإفلات الملفات هنا...</p>
                  ) : (
                    <div className={classes.AddImge}>
                      <p>...أدرج الصور هنا</p>
                      <IconButton>
                        <AddBox />
                      </IconButton>
                    </div>
                  )}
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    mt: 1,
                    mb: 1,
                    // justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={{
                      width: "50%",
                      bgcolor: "#1d3557",
                      color: "#fff",
                      fontSize: "13px",
                      fontWeight: 600,
                      zIndex: 2,
                      "&:hover": { color: "#1d3557" },
                    }}
                    startIcon={<InsertDriveFile />}
                    variant="outlined"
                  >
                    <Upgrade />
                    المتصفح
                    <input
                      type="file"
                      className={classes.btnImages}
                      multiple
                      onChange={handleFileSelect}
                    />
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </Box>
        )}
      </form>
    </Box>
  );
};
export default SaleADForm;
