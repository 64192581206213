// @ts-nocheck
// @ts-ignore
import React, { useContext, useState, useEffect } from "react";
import { Container, NavDropdown } from "react-bootstrap";
import { East } from "@mui/icons-material";
import "./topcategory.css";
import points from "./../admin-image/categ/points.svg";
import search from "./../../../assest/images/Landingpagevisitor/searchbar/search.svg";
// @ts-ignore
import { Categadmin } from "./Categadmin";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import CardsDashbord from "../Viewads/Carddashbord/CardsDashbord";
import { useNavigate, useParams } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import classes from "../View/ViewOffer.module.css";
import Snack from "components/Utilities/Snack";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const Inside = ({ title, setTitle, data, setData }) => {
  // @ts-ignore
  const { id } = useParams();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params.type);
  const [input, setInput] = useState(" ");
  const { authInfo } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const filterData = (e) => {
    const searchString = e.target.value;
    setInput(searchString);
    if (searchString === "") {
      // If the search input is empty, display all ads
      setData(data);
    } else {
      // Filter ads based on the search input
      const filteredAds = data.filter((ad) => ad.title.includes(searchString));
      setData(filteredAds);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const [categoryDeleted, setCategoryDeleted] = useState(false);
  const [error, setError] = useState("");
  const handleDelete = async () => {
    if (params.type === "nondeleted") {
      try {
        const response = await axios.delete(
          `https://api.whitesooq.com/api/v1/categories/delete/${params.id}`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        setOpen(false);
        handleClose();
        if (response?.data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف الفئة بنجاح",
          });
          setTimeout(() => {
            navigate("/nonDeletedData-page");
          }, 750);
        } else if (response?.data?.status === 0) {
          setAlert({
            open: true,
            type: "success",
            message: "هناك مشكلة في عملية الحذف",
          });
        }
      } catch (err) {
        if (err.request.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else if (err?.response?.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
          navigate("/");
        } else if (err?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: err?.response?.data?.message,
          });
        } else if (err?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "لا يوجد رد من الخادم",
          });
        }
      }
    } else {
      try {
        const response = await axios.delete(
          `https://api.whitesooq.com/api/v1/categories/${params.id}`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        setOpen(false);
        handleClose();
        if (response?.data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف الفئة بنجاح",
          });
          setTimeout(() => {
            navigate("/DeletedData-page");
          }, 750);
        } else if (response?.data?.status === 0) {
          setAlert({
            open: true,
            type: "warning",
            message: "هناك مشكلة في عملية الحذف",
          });
        }
      } catch (err) {
        if (err.request.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else if (err?.response?.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
          navigate("/");
        } else if (err?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: err?.response?.data?.message,
          });
        } else if (err?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "لا يوجد رد من الخادم",
          });
        }
      }
    }
  };
  const handleRestore = async () => {
    try {
      const response = await axios.put(
        `https://api.whitesooq.com/api/v1/categories/restore/${params.id}`,
        {},
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      console.log(response);
      setOpen(false);
      handleClose();
      if (response?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: "تمَّ استعادة الفئة بنجاح",
        });
        setTimeout(() => {
          navigate("/nonDeletedData-page");
        }, 750);
      } else if (response?.data?.status === 0) {
        setAlert({
          open: true,
          type: "success",
          message: "هناك مشكلة في عملية الاستعادة",
        });
      }
    } catch (err) {
      if (err.request.status === 403) {
        setAlert({
          open: true,
          type: "warning",
          message: "عذراً ليس لديك صلاحية",
        });
      } else if (err?.response?.status === 401) {
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
        navigate("/");
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "لا يوجد رد من الخادم",
        });
      }
    }
  };
  return (
    <div className="inside" dir="rtl" style={{ marginTop: "-60px" }}>
      <Container>
        <Snack alert={alert} />
        {params.type === "nondeleted" ? (
          <div style={{ direction: "rtl" }}>
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/nonDeletedData-page")}
            >
              <East />
            </IconButton>
          </div>
        ) : (
          <div style={{ direction: "rtl" }}>
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/DeletedData-page")}
            >
              <East />
            </IconButton>
          </div>
        )}
        <Box className={classes.menuOption}>
          <FormControl sx={{ mb: 2 }}>
            <Select
              IconComponent={ExpandMore}
              value={option}
              onChange={handleChange}
              displayEmpty
              autoWidth
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                onClick={handleClickOpen}
                sx={{ pl: 6 }}
                style={{ textAlign: "center" }}
              >
                حذف
              </MenuItem>

              <MenuItem
                onClick={() =>
                  navigate(`/edit-serices-page/${id}/${params.type}`)
                }
                sx={{ pl: 6 }}
              >
                تعديل
              </MenuItem>
              <MenuItem
                sx={{ pl: 6 }}
                onClick={() =>
                  navigate(`/infor-category-page/${id}/${params.type}`)
                }
              >
                معلومات الفئة
              </MenuItem>
              {params.type === "deleted" ? (
                <>
                  <MenuItem sx={{ pl: 6 }} onClick={handleRestore}>
                    استعادة
                  </MenuItem>
                </>
              ) : null}
            </Select>
          </FormControl>
          {!categoryDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {"هل انت متأكد من حذف هذه الفئة ؟"}
              </DialogTitle>
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 500,
                }}
              >
                {
                  "علما بأنَّه في حال تمَّ حذف الفئة فستصبح الفئات التابعة للفئة المحذوفة (فئة أساسية)"
                }
              </DialogTitle>
              <DialogActions sx={{ m: "0 auto" }}>
                <button
                  className={classes.btnCancelDialog}
                  onClick={handleClose}
                >
                  إلغاء
                </button>
                <button className={classes.btnOkDialog} onClick={handleDelete}>
                  متأكد
                </button>
              </DialogActions>
            </Dialog>
          )}
          {error && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {error}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Close sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  ok
                </button>
              </DialogActions>
            </Dialog>
          )}
          {categoryDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {"تم حذف الرسالة بنجاح"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Check sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  موافق
                </button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
        <h3 className="inside3" style={{ color: "red" }}>
          فئة {title}
        </h3>
        {params.type === "notdeleted" && (
          <h4 className="inside4">البحث عن أعلان</h4>
        )}
        {params.type === "nondeleted" && (
          <div className="col-lg-6">
            <form className="topcategsearch" dir="ltr">
              <img
                src={search}
                className="searchimg"
                style={{ marginLeft: "120px" }}
              />
              <input
                type="text"
                placeholder="بحث ..."
                name=""
                value={input}
                onChange={filterData}
              />
            </form>
          </div>
        )}
        <div className="adsrelation d-flex">
          <div className="blueshape"></div>
          <h5>
            الإعلانات ذات الصلة بالفئة المحددة{" "}
            <span> ({data.length} إعلان) </span>{" "}
          </h5>
        </div>
        <div className="row allcards">
          <CardsDashbord data={data} />
        </div>
      </Container>
    </div>
  );
};
