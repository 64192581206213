// @ts-nocheck
import { useContext, useState, useRef } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Alert,
  Image,
  Container,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "./../../../Admin/AddCategory/Arrow.svg";
import Check from "./../../../../assest/images/SettingAccount/AccountNotfication/Check.svg";
import "./SettingAccount.css";
import Footer from "../../../../layouts/Footer/Footer";
import Headerlogin from "../../../../layouts/Headerlogin/Headerlogin";
import Essentialicons from "./Essentialicons.svg";
import X from "./X.svg";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import Countries from "../../../../countries.json";
import { Avatar } from "@mui/material";
import Snack from "components/Utilities/Snack";
const SettingAccount = () => {
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState(authInfo?.user?.username);
  const [fullname, setFullname] = useState(authInfo?.user?.fullname);
  const [phone_number, setPhone_number] = useState(
    authInfo?.user?.phone_number
  );
  const [whatsapp, setWhatsapp] = useState(authInfo?.user?.whatsapp);
  const [country, setCountry] = useState(authInfo?.user?.country);
  const [image, setImage] = useState(authInfo?.user?.full_path_image);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  /*image*/
  const inputRef = useRef(null);
  const handleImageClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(e.target.files[0]);
  };
  const handleChangSubmit = async (event) => {
    event.preventDefault();
    if (!username || !fullname || !phone_number || !whatsapp || !country) {
      alert("الرجاء تعبئة الملف الشخصي");
      return;
    }
    const formData = new FormData();
    formData.append("username", username);
    formData.append("fullname", fullname);
    formData.append("phone_number", phone_number);
    formData.append("whatsapp", whatsapp);
    formData.append("country", country);
    if (selectedImage !== null) formData.append("image", selectedImage);
    // console.log(selectedImage)
    try {
      const response = await axios.post(
        `https://api.whitesooq.com/api/v1/users/${authInfo.user.id}`,
        formData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (response?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: " تم تعديل إعدادات الملف الشخصي",
        });
        ChangeAuthInfo({ ...authInfo, user: response.data.user });
      }
      // console.log(response);
    } catch (err) {
      // console.log(err);

      if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
        navigate("/");
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "الخادم لا يستجيب",
        });
      }
    }
  };
  const handleDeleteClick = async () => {
    try {
      const response = await axios.delete(
        `https://api.whitesooq.com/api/v1/users/${authInfo.user.id}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      await ChangeAuthInfo({});
      navigate("/");
    } catch (err) {
      if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        navigate("/");
      }
      // console.log(err);
    }
  };
  const handleVerification = async () => {
    // setShowConfirmationModal(true);
    // setShowSuccessModal(true);
    if (authInfo.user.phone_verified_at === null) {
      try {
        const response = await axios.post(
          `https://api.whitesooq.com/api/v1/mobile/verification-notification`,
          {},
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        if (response?.data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: response.data.message,
          });
          setTimeout(() => {
            navigate("/logIn/forgetPassword/varicationCode");
          }, 750);
        }
        // console.log(response);
      } catch (err) {
        if (err?.response?.status === 401) {
          ChangeAuthInfo({});
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
          navigate("/");
        }
        if (err?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: err?.response?.data?.message,
          });
        }
        if (err?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "لا يوجد رد من الخادم",
          });
        }
        // console.log(err);
      }
    } else {
      setAlert({
        open: true,
        type: "warning",
        message: "تمَّ تأكيد الحساب مسبقاً",
      });
    }
  };
  return (
    <div>
      <Snack alert={alert} />
      <Headerlogin />
      <div className="setting-account-container">
        <Container>
          <Form dir="rtl" className="form">
            <Col>
              <Button
                onClick={() => navigate("/")}
                style={{
                  backgroundColor: "white",
                  border: "1px solid white",
                  marginRight: "-10px",
                }}
              >
                <Image src={Arrow} />
              </Button>
            </Col>
            <Form.Label className="main-title">
              إعدادات الحساب الشخصي
            </Form.Label>
            <Row className="imageUpload">
              <Form>
                <Form.Group controlId="formGridEmail">
                  <Form.Label id="image" onClick={handleImageClick}>
                    <Avatar
                      className="upload-img"
                      sx={{ width: 278, height: 278 }}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : image
                      }
                      alt={username}
                    />
                    <svg
                      width="55"
                      height="51"
                      viewBox="0 0 55 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="camera">
                        <path
                          id="Vector"
                          d="M51.8529 40.3027C51.8529 41.4278 51.3862 42.5069 50.5556 43.3025C49.725 44.0981 48.5985 44.5451 47.4239 44.5451H7.56324C6.38861 44.5451 5.26208 44.0981 4.43149 43.3025C3.6009 42.5069 3.13428 41.4278 3.13428 40.3027V16.9693C3.13428 15.8442 3.6009 14.7651 4.43149 13.9695C5.26208 13.1739 6.38861 12.7269 7.56324 12.7269H16.4212L20.8501 6.36328H34.137L38.566 12.7269H47.4239C48.5985 12.7269 49.725 13.1739 50.5556 13.9695C51.3862 14.7651 51.8529 15.8442 51.8529 16.9693V40.3027Z"
                          stroke="#F1FAEE"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M27.4937 36.06C32.3858 36.06 36.3516 32.2612 36.3516 27.5752C36.3516 22.8891 32.3858 19.0903 27.4937 19.0903C22.6016 19.0903 18.6357 22.8891 18.6357 27.5752C18.6357 32.2612 22.6016 36.06 27.4937 36.06Z"
                          stroke="#F1FAEE"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                  </Form.Label>
                </Form.Group>
              </Form>
              <input
                bill
                type="file"
                ref={inputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </Row>

            {/*  <input
                            type="file"
                            onChange={(e) => {
                                setSelectedImage(e.target.files[0]);
                            }}
                        />*/}
            <Col>
              <Form.Label className="second-title">
                المعرف الشخصي للحساب{" "}
                <span style={{ fontWeight: "600" }}>
                  {authInfo ? authInfo.user.id : ""}
                </span>
              </Form.Label>
            </Col>
            <Form.Group controlId="username-input">
              <Form.Label style={{ color: "#1D3557", fontSize: "16px" }}>
                اسم المستخدم
              </Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={username}
                placeholder={"Ranim01"}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="fullname-input">
              <Form.Label style={{ color: "#1D3557", fontSize: "16px" }}>
                الاسم بالكامل
              </Form.Label>
              <Form.Control
                type="text"
                name="fullname"
                value={fullname}
                placeholder="مثال: رنيم أحمد عمر"
                onChange={(e) => setFullname(e.target.value)}
              />
            </Form.Group>
            <div className="rooww">
              <Form.Group as={Col} controlId="phone-number-input">
                <Form.Label
                  style={{
                    color: "#1D3557",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  رقم الهاتف{" "}
                </Form.Label>
                <Form.Control
                  className="phone-number"
                  type="text"
                  name="phone_number"
                  value={phone_number}
                  placeholder="999 555 667 962+"
                  onChange={(e) => setPhone_number(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="whatsapp-input">
                <Form.Label
                  style={{
                    color: "#1D3557",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  رقم الهاتف الخاص بالواتساب{" "}
                </Form.Label>
                <Form.Control
                  className="phone-number"
                  type="text"
                  name="whatsapp"
                  value={whatsapp}
                  placeholder="999555667 962+ "
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>
            </div>
            <Form.Group as={Col} controlId="country-select">
              <Form.Label>البلد </Form.Label>
              <Form.Select
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="input-select"
              >
                {Object.entries(Countries).map((Country) => (
                  <option key={Country[0]}> {Country[1]} </option>
                ))}
              </Form.Select>
              {country === "" && (
                <p
                  style={{
                    color: "#E63946",
                    fontSize: "10px",
                    marginTop: "0",
                  }}
                >
                  الرجاء اختيار بلد
                </p>
              )}
            </Form.Group>
            <Button
              className="confirm-delete-button"
              type="button"
              onClick={() => setShowConfirmationModal(true)}
              style={{
                backgroundColor: "#E63946",
                border: "1px solid #E63946",
              }}
            >
              حذف الحساب
            </Button>
            <Modal
              show={showConfirmationModal}
              onHide={() => setShowConfirmationModal(false)}
              centered
            >
              <Modal.Body style={{ textAlign: "center" }}>
                <Image src={X} />
                <p
                  style={{
                    color: "#1D3557",
                    fontSize: "24px",
                    fontFamily: "Noto Sans Arabic",
                    marginTop: "16px",
                  }}
                >
                  هل أنت متأكد من حذف هذا الحساب؟
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={handleDeleteClick}
                    style={{
                      alignItems: "center",
                      width: "126px",
                      padding: "10px 38px",
                      justifyContent: "center",
                      backgroundColor: "#457B9D",
                      fontSize: "24px",
                      height: "50px",
                      marginLeft: "20px",
                    }}
                  >
                    متأكد
                  </Button>
                  <Link to={"/"}>
                    <Button
                      style={{
                        alignItems: "center",
                        width: "126px",
                        padding: "10px 38px",
                        justifyContent: "center",
                        backgroundColor: "white",
                        color: "#1D3557",
                        border: "1px solid #1D3557",
                        fontSize: "24px",
                        height: "50px",
                      }}
                    >
                      إلغاء
                    </Button>
                  </Link>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showSuccessModal}
              onHide={() => setShowSuccessModal(false)}
              centered
            >
              <Modal.Body style={{ textAlign: "center" }}>
                <Image src={Essentialicons} style={{ marginTop: "15px" }} />
                <p
                  style={{
                    color: "#1D3557",
                    fontSize: "24px",
                    fontFamily: "Noto Sans Arabic",
                    marginTop: "16px",
                  }}
                >
                  تم تقديم طلب حذف الحساب إلى المسؤولين
                  <br />
                  (الرجاء الانتظار إلى حين الإجابة لطلبك الحالي)
                  <br />
                  <span style={{ color: "#457B9D" }}>
                    علماَ أنه لايمكنك استخدام حسابك حتى يتم رفض
                    <br />
                    الطلب وإعادة تفعيل الحساب
                  </span>
                </p>
                <Button
                  onClick={() => setShowSuccessModal(false)}
                  style={{
                    alignItems: "center",
                    width: "126px",
                    padding: "16px 48px",
                    justifyContent: "center",
                    backgroundColor: "#457B9D",
                    fontSize: "24px",
                    height: "50px",
                    marginLeft: "140px",
                  }}
                >
                  موافق
                </Button>
              </Modal.Body>
            </Modal>

            <Button
              onClick={handleChangSubmit}
              type="button"
              className="edit-buttonnn peer"
              style={{
                backgroundColor: "#457B9D",
                whiteSpace: "nowrap",
              }}
            >
              <Link
                style={{
                  marginRight: "-17px",
                  textDecoration: "none",
                  color: "#FFF",
                }}
              >
                {" "}
                تعديل الملف الشخصي
              </Link>
            </Button>
            <Link to="/">
              <Button
                type="button"
                className="edit-buttonnn peer2"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#457B9D",
                  textDecoration: "none",
                }}
              >
                إلغاء
              </Button>
            </Link>
          </Form>
          {showAlert && (
            <Alert className="custom-alert">
              تم تعديل اعدادات الملف الشخصي
              <Image style={{ marginLeft: "40%" }} src={Check} />{" "}
            </Alert>
          )}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default SettingAccount;
