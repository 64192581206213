// @ts-nocheck
import { useContext, useState } from "react";
import axios from "axios";
import "./Login.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { Modal, ModalFooter, ModalHeader } from "react-bootstrap";

const Login = (props) => {
  const navigate = useNavigate();
  const [showLogIn, setShowLogIn] = useState(false);
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("login");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [logInData, setlogInData] = useState({});
  const [token, setToken] = useState("");

  const [userInfo, setUserinfo] = useState({
    phone_number: "",
    password: "",
    remember_me: false,
  });
  const [forgetUserPassowrd, setForgetUserPassowrd] = useState({
    username: "",
    phone_number: "",
  });

  const [resetUserInfo, setResetUserinfo] = useState({
    password: "",
    password_confirmation: "",
    phone_number: "",
    code: "",
  });

  // add the value of the checkbox to the state
  const handleCheck = () => {
    setIsChecked(!isChecked);
    setUserinfo({ ...userInfo, remember_me: isChecked });
  };
  // store the data that is in the forget page
  const handleForgetForm = (e) => {
    setForgetUserPassowrd({
      ...forgetUserPassowrd,
      [e.target.name]: e.target.value,
    });
    setAction("forgetPassword");
  };
  // store the data that is in the reset page

  const handleResetForm = (e) => {
    setResetUserinfo({
      ...resetUserInfo,
      [e.target.name]: e.target.value,
      phone_number: localStorage.getItem("phone_number"),
      code: localStorage.getItem("code"),
    });
    setAction("resetpassword");
  };
  // store the data that is in the logIn page

  const handleLogInForm = (e) => {
    setUserinfo({ ...userInfo, [e.target.name]: e.target.value });
    setAction("logIn");
  };
  //  function to choose which state saves the data depending on the opened page
  const handleForm = (e) => {
    props.type === "forgetPassword"
      ? handleForgetForm(e)
      : props.type === "resetpassword"
      ? handleResetForm(e)
      : handleLogInForm(e);

    localStorage.setItem("action", action);
  };
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  // fetch data
  const navUser = () => {
    if (authInfo?.user.is_admin === "0") navigate("/");
    else if (authInfo?.user.is_admin === "1") navigate("/ViewActiveAds-page");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setisLoading(true);
    setShowLogIn(true);
    props.type === "forgetPassword"
      ? axios
          .post(
            " https://api.whitesooq.com/api/v1/forgot-password ",
            forgetUserPassowrd,
            {
              headers: {
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 1) {
              setShowLogIn(false);
              setStatus("1");
              navigate("resetpassword");
            } else setMessage(response.data.message);
            setisLoading(false);
          })
          .catch((Error) => {
            console.log(Error);
            setMessage(Error.response.data.message);
            setisLoading(false);
          })
      : props.type === "resetpassword"
      ? axios
          .post(
            " https://api.whitesooq.com/api/v1/reset-password ",
            resetUserInfo,
            {
              headers: {
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              },
            }
          )
          .then((response) => {
            setMessage(response.data.message);
            setStatus("1");
            setisLoading(false);
            setAction("resetPassowrd");
          })
          .catch((Error) => {
            console.log(Error);
            setMessage(Error.response.data.message);
            setisLoading(false);
          })
      : axios
          .post(" https://api.whitesooq.com/api/v1/login ", userInfo, {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          })
          .then((response) => {
            if (response?.data?.token) {
              ChangeAuthInfo(response.data);
              localStorage.setItem("token", response.data.token);
              setlogInData(response.data.user);
              setToken(response.data.token);
            }
            setMessage(response.data.message);
            setAction("logIn");
            setisLoading(false);

            setStatus("1");
          })
          .catch((Error) => {
            console.log(Error);
            if (Error?.response?.data?.message) {
              setMessage(Error.response.data.message);
              setisLoading(false);
            }
          });
  };
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
    >
      <g filter="url(#filter0_d_171_1455)">
        <circle cx="35" cy="31" r="20" fill="#F2F2F2" />
      </g>
      <path
        d="M41 23.7109L29 35.2119"
        stroke="#E63946"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 23.7109L41 35.2119"
        stroke="#E63946"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_171_1455"
          x="0"
          y="0"
          width="70"
          height="70"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.611613 0 0 0 0 0.679146 0 0 0 0 0.721667 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_171_1455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_171_1455"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const phoneIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_1700_2971)">
        <path
          d="M15.0499 5.5C16.0267 5.69057 16.9243 6.16826 17.628 6.87194C18.3317 7.57561 18.8094 8.47326 18.9999 9.45M15.0499 1.5C17.0792 1.72544 18.9715 2.63417 20.4162 4.07701C21.8608 5.51984 22.7719 7.41101 22.9999 9.44M21.9999 17.42V20.42C22.0011 20.6985 21.944 20.9742 21.8324 21.2293C21.7209 21.4845 21.5572 21.7136 21.352 21.9019C21.1468 22.0901 20.9045 22.2335 20.6407 22.3227C20.3769 22.4119 20.0973 22.4451 19.8199 22.42C16.7428 22.0856 13.7869 21.0341 11.1899 19.35C8.77376 17.8147 6.72527 15.7662 5.18993 13.35C3.49991 10.7412 2.44818 7.77099 2.11993 4.68C2.09494 4.40347 2.12781 4.12476 2.21643 3.86162C2.30506 3.59849 2.4475 3.35669 2.6347 3.15162C2.82189 2.94655 3.04974 2.78271 3.30372 2.67052C3.55771 2.55833 3.83227 2.50026 4.10993 2.5H7.10993C7.59524 2.49522 8.06572 2.66708 8.43369 2.98353C8.80166 3.29999 9.04201 3.73945 9.10993 4.22C9.23656 5.18007 9.47138 6.12273 9.80993 7.03C9.94448 7.38792 9.9736 7.77691 9.89384 8.15088C9.81408 8.52485 9.6288 8.86811 9.35993 9.14L8.08993 10.41C9.51349 12.9135 11.5864 14.9864 14.0899 16.41L15.3599 15.14C15.6318 14.8711 15.9751 14.6858 16.3491 14.6061C16.723 14.5263 17.112 14.5555 17.4699 14.69C18.3772 15.0286 19.3199 15.2634 20.2799 15.39C20.7657 15.4585 21.2093 15.7032 21.5265 16.0775C21.8436 16.4518 22.0121 16.9296 21.9999 17.42Z"
          stroke="#1D3557"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1700_2971">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const circleIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <circle cx="20" cy="16.5" r="7" fill="#1D3557" />
      <g filter="url(#filter0_d_1786_866)">
        <circle
          cx="20"
          cy="16.5"
          r="9.5"
          stroke="#1D3557"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1786_866"
          x="0"
          y="0.5"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1786_866"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1786_866"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  return (
    <div className="login">
      {/* alert */}
      <section className="logInAlert">
        <Modal
          show={showLogIn}
          variant="none"
          style={{ direction: "rtl" }}
          backdrop="static"
          centered
        >
          {isLoading ? (
            <>
              <ModalHeader> تتم العملية الان الرجاء الانتظار </ModalHeader>
              <div class="typewriter">
                <div class="slide">
                  <i></i>
                </div>
                <div class="paper"></div>
                <div class="keyboard"></div>
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <ModalHeader>{message}</ModalHeader>
              <ModalFooter className="d-flex justify-content-center">
                <Button
                  onClick={() => setShowLogIn(false)}
                  style={{
                    backgroundColor: "#1D3557",
                    marginLeft: "30px",
                  }}
                >
                  {status === "1" && action === "resetPassowrd" ? (
                    <Link
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                      to="/login"
                    >
                      موافق
                    </Link>
                  ) : status === "1" && action === "logIn" ? (
                    <span
                      onClick={() => navUser()}
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                      to="/ "
                    >
                      موافق
                    </span>
                  ) : (
                    "موافق"
                  )}
                </Button>
              </ModalFooter>{" "}
            </>
          )}
        </Modal>
        {!showLogIn}
      </section>

      <Form onSubmit={handleClick}>
        {/* */}

        {props.type === "forgetPassword" ? (
          <Link to="/logIn">{closeIcon}</Link>
        ) : props.type === "resetpassword" ? (
          <Link to="/logIn/forgetPassword">{closeIcon}</Link>
        ) : (
          <Link to="/">{closeIcon}</Link>
        )}

        {/*        */}
        {props.type === "forgetPassword" ? (
          <Form.Text id="rightTitle" className="fs-3">
            {" "}
            نسيت كلمة المرور{" "}
          </Form.Text>
        ) : props.type === "resetpassword" ? (
          <Form.Text id="rightTitle" className="fs-3">
            {" "}
            تغيير كلمة المرور{" "}
          </Form.Text>
        ) : (
          <Form.Text id="logInTitle" className="fs-3">
            {" "}
            تسجيل الدخول{" "}
          </Form.Text>
        )}

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="fs-6">
            {" "}
            {props.type === "forgetPassword"
              ? " ادخل اسم المستخدم  "
              : props.type === "resetpassword"
              ? "كلمة المرور الجديدة "
              : "الرجاء ادخال رقم الهاتف"}{" "}
          </Form.Label>
          {props.type === "forgetPassword" ? (
            <Form.Control
              className="fs-6"
              type="text"
              placeholder="Ranim01"
              name="username"
              onChange={handleForm}
            />
          ) : props.type === "resetpassword" ? (
            <Form.Control
              type="text"
              placeholder="************"
              name="password"
              onChange={handleForm}
            />
          ) : (
            <Form.Control
              className="fs-6 px-3"
              type="text"
              placeholder="099999999 "
              name="phone_number"
              onChange={handleForm}
            />
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="fs-6">
            {" "}
            {props.type === "forgetPassword"
              ? " الرجاء ادخال رقم الهاتف "
              : props.type === "resetpassword"
              ? " تأكيد كلمة المرور الجديدة"
              : " الرجاء ادخال كلمة المرور"}{" "}
          </Form.Label>
          <span id="secondInput">
            {props.type === "forgetPassword" ? (
              <Form.Control
                className="fs-6 pe-5"
                type="text"
                placeholder="+962 09999999"
                name="phone_number"
                onChange={handleForm}
              />
            ) : props.type === "resetpassword" ? (
              <Form.Control
                className="fs-6 px-3"
                type="text"
                placeholder="************"
                name="password_confirmation"
                onChange={handleForm}
              />
            ) : (
              <Form.Control
                type="password"
                placeholder="************ "
                name="password"
                onChange={handleForm}
              />
            )}

            {props.type === "forgetPassword" ? (
              <>
                {" "}
                <span id="leftIcon"> {phoneIcon} </span>
                <span id="rightIcon"> {circleIcon} </span>
              </>
            ) : (
              <></>
            )}
          </span>
        </Form.Group>
        {/*            */}

        {props.type === "forgetPassword" || props.type === "resetpassword" ? (
          <></>
        ) : (
          <span className="logInspan">
            <Link to="forgetPassword">هل نسيت كلمة المرور ؟ </Link>
            <span className="check">
              <Link> تذكر كلمة المرور لمدة اسبوع </Link>
              <input type="checkbox" onChange={handleCheck} />
            </span>
          </span>
        )}
        {/*          */}

        <Button
          variant="primary"
          type="submit"
          style={{ width: "100%" }}
          className={
            props.type === "resetpassword" || props.type === "forgetPassword"
              ? "mt-4"
              : ""
          }
        >
          <Link
            style={{ textDecoration: "none", color: "white" }}
            className="dokhol"
          >
            {props.type === "resetpassword" || props.type === "forgetPassword"
              ? "التالي"
              : "دخول"}
          </Link>
        </Button>
        {props.type === "resetpassword" || props.type === "forgetPassword" ? (
          ""
        ) : (
          <h2 id="gotosign">
            {" "}
            <Link
              to="/signup"
              style={{ textDecoration: "none", color: "#1D3557" }}
            >
              {" "}
              ليس لديك حساب؟ أنشئ حسابك الان وابدأ البيع.{" "}
            </Link>{" "}
          </h2>
        )}
      </Form>
    </div>
  );
};

export default Login;
