import React, { useRef, useState, useContext } from "react";
import classes from "./AddPackageForm.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ButtonAD from "../../User/Ads/Button/ButtonAD";
import Select from "@mui/material/Select";
import { AuthContext } from "store/UseAuthContext";
import { Box, Grid } from "@mui/material";
import Snack from "components/Utilities/Snack";
import { useNavigate } from "react-router-dom";
const AddPackageForm = () => {
  const { authInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const namePackageRef = useRef();
  const detilsPackageRef = useRef();
  const PriceRef = useRef();
  const validityPackageRef = useRef();
  const periodOfVliditySale = useRef();
  const countOfSpreadSaleAds = useRef();
  const periodOfValidityPinAdRef = useRef();
  const periodValidityPurchaseAdRef = useRef();
  const countOfSpreadPurchaseAds = useRef();
  const serviceDiscounts = useRef();
  const [error, setError] = useState("");
  const [typePackage, setTypePackage] = useState("عامة");
  const [validityEditSaleAD, setValidityEditSaleAD] = useState("نعم");
  const [validityGetStaticsSaleAD, setValidityGetStaticsSaleAD] =
    useState("نعم");
  const [countSpreadOffers, setCountSpreadOffers] = useState("لا");
  const [hideValueOffer, setHideValueOffer] = useState("نعم");
  const [highlightOffer, setHighlightOffer] = useState("نعم");
  const [pinAds, setPinAds] = useState("لا");
  const [validityEditPurchaseAD, setValidityEditPurchaseAD] = useState("نعم");
  const [validityGetStaticsPurchaseAD, setValidityGetStaticsPurchaseAD] =
    useState("لا");
  const [discount, setDiscount] = useState("نعم");
  const submitHandler = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    const enteredNamePackage = namePackageRef.current.value;
    const enteredDetailsPackage = detilsPackageRef.current.value;
    const enteredPricePackage = PriceRef.current.value;
    const enteredValidityPackage = validityPackageRef.current.value;
    const enteredPeriodOfVliditySale = periodOfVliditySale.current.value;
    const enteredCountOfSpreadSaleAds = countOfSpreadSaleAds.current.value;
    const enteredPeriodOfValidityPinAdRef =
      periodOfValidityPinAdRef.current.value;
    const enteredPeriodValidityPurchaseAdRef =
      periodValidityPurchaseAdRef.current.value;
    const enteredCountOfSpreadPurchaseAds =
      countOfSpreadPurchaseAds.current.value;
    const enteredServiceDiscounts = serviceDiscounts.current.value;
    let sale_ads_updateable = validityEditSaleAD === "نعم" ? 1 : 0;
    let sale_ads_resultable = validityGetStaticsSaleAD === "نعم" ? 1 : 0;
    let buy_ads_updateable = validityEditPurchaseAD === "نعم" ? 1 : 0;
    let buy_ads_resultable = validityGetStaticsPurchaseAD === "نعم" ? 1 : 0;
    let hide_offer = hideValueOffer === "نعم" ? 1 : 0;
    let offer_highlighting = highlightOffer === "نعم" ? 1 : 0;
    let pinable = pinAds === "نعم" ? 1 : 0;
    let disc = discount === "نعم" ? 1 : 0;
    let typePack = typePackage === "خاصة" ? "private" : "public";
    const formData = new FormData();
    formData.append("name", enteredNamePackage);
    formData.append("description", enteredDetailsPackage);
    formData.append("cost", enteredPricePackage);
    formData.append("validity", enteredValidityPackage);
    formData.append("type", typePack);
    // @ts-ignore
    formData.append("sale_ads_validity", +enteredPeriodOfVliditySale);
    formData.append("sale_ads_limit", +enteredCountOfSpreadSaleAds);
    formData.append("sale_ads_updateable", sale_ads_updateable);
    formData.append("sale_ads_resultable", sale_ads_resultable);
    formData.append("buy_ads_validity", +enteredPeriodValidityPurchaseAdRef);
    formData.append("buy_ads_limit", +enteredCountOfSpreadPurchaseAds);
    formData.append("buy_ads_updateable", buy_ads_updateable);
    formData.append("buy_ads_resultable", buy_ads_resultable);
    formData.append("offers_limit", +countSpreadOffers);
    formData.append("service_discounts", +enteredServiceDiscounts);
    formData.append("hide_offer", hide_offer);
    formData.append("pinable", pinable);
    formData.append("offer_highlighting", offer_highlighting);
    formData.append("pinable_validity", +enteredPeriodOfValidityPinAdRef);
    formData.append("discount", disc);
    // console.log(formData);
    fetch("https://api.whitesooq.com/api/v1/packages", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
      body: formData,
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        return response.json();
      })
      .then((response) => {
        console.log(response);
        if (response?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّت الإضافة بنجاح",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: response?.message,
          });
        }
      })
      .catch((error) => setError(error.message));
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  return (
    <Box className={classes.NewPackage}>
      <Snack alert={alert} />
      <form onSubmit={submitHandler}>
        {/* <!--Start Name Package--> */}
        <div className={classes.control}>
          <label htmlFor="name_package">
            اسم الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="name_package"
            required
            ref={namePackageRef}
            placeholder="باقة خاصة"
          />
        </div>
        {/* <!--End Name Package--> */}
        {/* <!--Start Details Package--> */}
        <div className={classes.control}>
          <label htmlFor="details_package">
            وصف الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="details_package"
            required
            ref={detilsPackageRef}
            placeholder="وصف باقة"
          />
        </div>
        {/* <!--End Details Package--> */}
        {/* <!--Start Cost Package--> */}
        <div className={classes.control}>
          <label htmlFor="Cost_Package">
            تكلفة الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="Cost_Package"
            required
            ref={PriceRef}
            placeholder="1000"
          />
        </div>
        {/* <!--End Cost Package--> */}
        {/* Start Validity edit Ads */}
        <div className={classes.control}>
          <label htmlFor="discount">
            إضافة خصم على الباقة<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{discount}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="discount"
                value={discount}
                label="type"
                placeholder="نعم"
                onChange={(e) => setDiscount(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity edit Ads */}
        {/* <!--Start Validity Package--> */}
        <div className={classes.control}>
          <label htmlFor="validity_Package">
            صلاحية الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="validity_Package"
            required
            ref={validityPackageRef}
            placeholder="1"
          />
        </div>
        {/* <!--End Validity Package--> */}
        {/* Start Type package */}
        <div className={classes.control}>
          <label htmlFor="type_package">
            نوع الباقة<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {typePackage}
              </InputLabel>
              <Select
                className={classes.inputDetails}
                labelId="demo-simple-select-label"
                id="type_package"
                value={typePackage}
                label="type"
                placeholder="عامة"
                onChange={(e) => setTypePackage(e.target.value)}
              >
                {["عامة", "خاصة"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <!--End type package--> */}
        {/* <!--Start period of Validity Sale Ads--> */}
        <div className={classes.control}>
          <label htmlFor="validity_Period">
            مدة صلاحية إعلانات البيع<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="validity_Period"
            required
            ref={periodOfVliditySale}
            placeholder="3"
          />
        </div>
        {/* <!--End period of Validity Sale Ads--> */}
        {/* <!--Start count spread Sale Ads --> */}
        <div className={classes.control}>
          <label htmlFor="count_spread">
            حد نشر إعلانات البيع<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="count_spread"
            required
            ref={countOfSpreadSaleAds}
            placeholder="5"
          />
        </div>
        {/* <!--End count spread Sale Ads--> */}
        {/* Start Validity edit Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_edit_Ads">
            سماحية تعديل إعلانات البيع<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {validityEditSaleAD}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_edit_Ads"
                value={validityEditSaleAD}
                label="type"
                placeholder="نعم"
                onChange={(e) => setValidityEditSaleAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity edit Ads */}
        {/* Start Validity to get statics Sale Ads*/}
        <div className={classes.control}>
          <label htmlFor=" Validity_get_statics_Sale_Ads">
            سماحية الحصول على إحصائيات إعلانات البيع<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {validityGetStaticsSaleAD}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_get_statics_Sale_Ads"
                value={validityGetStaticsSaleAD}
                label="type"
                placeholder="لا"
                onChange={(e) => setValidityGetStaticsSaleAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity to get statics Sale Ads*/}
        {/* Start count spread offres*/}
        <div className={classes.control}>
          <label htmlFor="count_spread_offers">
            حد نشر العروض<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="count_spread_offers"
            required
            value={countSpreadOffers}
            onChange={(e) => setCountSpreadOffers(e.target.value)}
            placeholder="5"
          />
        </div>
        {/* End count spread offres*/}
        {/* Start Hide value offer */}
        <div className={classes.control}>
          <label htmlFor="Hide_value_offer">
            إخفاء قيمة العرض<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {hideValueOffer}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Hide_value_offer"
                value={hideValueOffer}
                label="type"
                placeholder="نعم"
                onChange={(e) => setHideValueOffer(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Hide value offer */}
        {/* Start Highlight offer*/}
        <div className={classes.control}>
          <label htmlFor="Highlight_offer">
            تمييز العرض<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {highlightOffer}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Highlight_offer"
                value={highlightOffer}
                label="type"
                placeholder="نعم"
                onChange={(e) => setHighlightOffer(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Highlight offer*/}
        {/* Start Pin Ads */}
        <div className={classes.control}>
          <label htmlFor="Pin_Ads">
            تثبيت الإعلانات<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{pinAds}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Pin_Ads"
                value={pinAds}
                label="type"
                placeholder="لا"
                onChange={(e) => setPinAds(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Pin Ads */}
        {/* Start period of validity Pin Ad */}
        <div className={classes.control}>
          <label htmlFor="period_validity_pin_AD">
            مدة صلاحية تثبيت الإعلان<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="period_validity_pin_AD"
            required
            ref={periodOfValidityPinAdRef}
            placeholder="3"
          />
        </div>
        {/* End period of validity Pin Ad */}
        {/* Start period of spread Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="period_validity_purchase_Ad">
            مدة صلاحية إعلانات الشراء<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="period_validity_purchase_Ad"
            required
            ref={periodValidityPurchaseAdRef}
            placeholder="3"
          />
        </div>
        {/* End period of spread Purchase Ads */}
        {/* Start count of spread Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="count_spread_purchase_Ad">
            حد نشر إعلانات الشراء<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="count_spread_purchase_Ad"
            required
            ref={countOfSpreadPurchaseAds}
            placeholder="5"
          />
        </div>
        {/* End count of spread Purchase Ads */}
        {/* Start Validity edit Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_edit_Purchase_Ads">
            سماحية تعديل إعلاناات الشراء<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {validityEditPurchaseAD}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_edit_Purchase_Ads"
                value={validityEditPurchaseAD}
                label="type"
                placeholder="نعم"
                onChange={(e) => setValidityEditPurchaseAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity edit Purchase Ads */}
        {/* Start Validity to get statics purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_get_statics_purchase_Ads">
            سماحية الحصول على إحصائيات إعلاناات الشراء
            <span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {validityGetStaticsPurchaseAD}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_get_statics_purchase_Ads"
                value={validityGetStaticsPurchaseAD}
                label="type"
                placeholder="لا"
                onChange={(e) =>
                  setValidityGetStaticsPurchaseAD(e.target.value)
                }
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity to get statics purchase Ads */}
        {/* <!--Start service discounts Ads--> */}
        <div className={classes.control}>
          <label htmlFor="service_discounts">
            خصومات الخدمات<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="service_discounts"
            required
            ref={serviceDiscounts}
            placeholder="10"
          />
        </div>
        {/* <!--End Validity Ads--> */}
        {/* <!--Start Button ADD AD--> */}
        <div className={classes.control} style={{ direction: "ltr" }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
            item
            xs={8}
          >
            <ButtonAD title={"إضافة"} />
          </Grid>
        </div>
        {/* <!--End Button ADD AD--> */}
      </form>
    </Box>
  );
};
export default AddPackageForm;
