// @ts-nocheck
import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Col,
  Row,
  Alert,
  Modal,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import { ArrowRight } from "./ArrowRight";
import { Link, useNavigate } from "react-router-dom";
import Headerlogin from "layouts/Headerlogin/Headerlogin";
import Footer from "layouts/Footer/Footer";
import axios from "axios";
import "./ChangePassword.css";

const Change = () => {
  // State variables to store input values and feedback
  const [old_password, setOld_password] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [status, setStatus] = useState("0");
  const [message, setMessage] = useState("");

  // State variable to control the visibility of the success/failure alert
  const [showLogIn, setShowLogIn] = useState(false);

  // React Router's navigation function
  const navigate = useNavigate();

  // Event handler for the current password input
  const handleCurrentPassword = (event) => {
    setOld_password(event.target.value);
  };

  // Event handler for the new password input
  const handleNewPassword = (event) => {
    setPassword(event.target.value);
  };

  // Event handler for the password confirmation input
  const handleConfirmPassword = (event) => {
    setPassword_confirmation(event.target.value);
    // Check if the password and password confirmation match
    event.target.value === password
      ? setPasswordMatch(true)
      : setPasswordMatch(false);
  };

  // Function to handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send a POST request to the server to change the password
    axios
      .post(
        "https://api.whitesooq.com/api/v1/change-password",
        {
          current_password: old_password,
          password: password,
          password_confirmation: password_confirmation,
        },
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        // Update status and message based on the response
        setStatus("1");
        setMessage(response.data.message);
        setShowLogIn(true);
      })
      .catch((err) => {
        console.log(err);
        // Update status and message based on the error response
        setStatus("0");
        setMessage(err.response.data.message);
        setShowLogIn(true);
      });
  };

  return (
    <div className="changepassword">
      <section className="changeAlert">
        <Modal
          show={showLogIn}
          variant="none"
          style={{ direction: "rtl" }}
          backdrop="static"
          centered
        >
          <ModalHeader>{message}</ModalHeader>

          <ModalFooter className="d-flex justify-content-start">
            <Button
              onClick={() => setShowLogIn(false)}
              style={{ backgroundColor: "#1D3557", marginRight: "180px" }}
            >
              {/* Redirect to the home page on success, or stay on the change password page on failure */}
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={status === "1" ? "/" : "/change"}
              >
                موافق
              </Link>
            </Button>
          </ModalFooter>
        </Modal>
      </section>
      {/* Header component */}
      <Headerlogin />
      <Container>
        <Form
          className="form"
          dir="rtl"
          onSubmit={handleSubmit}
          style={{
            borderRadius: "40px",
            marginTop: "127px",
            marginRight: "-35px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Col style={{ marginTop: "8.33px" }}>
            <Link style={{ textDecoration: "none" }} to="/">
              <ArrowRight />
            </Link>
          </Col>
          <Col>
            <h2
              style={{
                color: "#1D3557",
                fontSize: "35px",
                marginTop: "16px",
              }}
            >
              تغيير كلمة المرور
            </h2>
          </Col>
          {/* Input fields for the current password, new password, and password confirmation */}
          <Form.Group controlId="currentPassword">
            <Form.Label
              style={{ color: "#1D3557", fontSize: "16px", marginTop: "20px" }}
            >
              كلمة المرور الحالية{" "}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="**************"
              value={old_password}
              onChange={handleCurrentPassword}
              required
              style={{
                border: "2px solid #457B9D",
                height: "57px",
                borderRadius: "9px",
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "15px",
                placeholder: "#1D3557",
              }}
            />
          </Form.Group>

          <Form.Group controlId="newPassword">
            <Form.Label style={{ color: "#1D3557", fontSize: "16px" }}>
              كلمة المرور الجديدة
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="*********"
              value={password}
              onChange={handleNewPassword}
              required
              minLength={8}
              style={{
                border: "2px solid #457B9D",
                height: "57px",
                borderRadius: "9px",
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "15px",
                placeholder: "#1D3557",
              }}
            />
          </Form.Group>

          <Form.Group controlId="confirmPassword">
            <Form.Label style={{ color: "#1D3557", fontSize: "16px" }}>
              تأكيد كلمة المرور
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="*********"
              value={password_confirmation}
              onChange={handleConfirmPassword}
              required
              minLength={8}
              style={{
                border: "2px solid #457B9D",
                height: "57px",
                borderRadius: "9px",
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "15px",
                placeholder: "#1D3557",
              }}
            />
            {passwordMatch ? (
              <Form.Text className="text-success">
                كلمات المرور متطابقة
              </Form.Text>
            ) : (
              <Form.Text className="text-danger">
                كلمات المرور غير متطابقة
              </Form.Text>
            )}
          </Form.Group>
          {/* Submit and Cancel buttons */}
          <>
            <>
              <Button
                type="submit"
                style={{
                  border: "2px solid #457B9D",
                  backgroundColor: "#457B9D",
                  height: "55px",
                  borderRadius: "10px",
                  marginTop: "10px",
                  marginBottom: "30px",
                  cursor: "pointer",
                  width: "212px",
                }}
              >
                تعديل كلمة المرور
              </Button>
              <Link to="/">
                <Button
                  type="button"
                  style={{
                    border: "2px solid #457B9D",
                    backgroundColor: "#FFFFFF",
                    color: "#1D3557",
                    height: "55px",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginBottom: "30px",
                    cursor: "pointer",
                    width: "212px",
                    marginRight: "20px",
                    textDecoration: "none",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: " #1D3557",
                  }}
                >
                  إلغاء
                </Button>
              </Link>
            </>
          </>
        </Form>
      </Container>
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default Change;
