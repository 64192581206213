// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
// @ts-ignore
import { Divider, Stack } from "@mui/material";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import Home from "../AddCategory/Home.svg";
import { Form, Image } from "react-bootstrap";
export default function Statstics() {
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  // console.log(data);
  //
  const CategoriesFilter = ["أكثر الفئات رواجا", "أكثر الفئات بحثا"];
  const [categoryFilter, setCategoryFilter] = useState(CategoriesFilter[0]);
  const changeCategoryFilter = (itemSelected) => {
    setCategoryFilter(itemSelected);
  };
  // console.log(categoryFilter);
  // Year Menu
  const currentDate = new Date();
  const years = [];
  for (let i = 2023; i <= currentDate.getFullYear(); i++) {
    years.push(i);
  }
  // console.log(years);
  const [anchorElYear, setAnchorElYear] = useState(null);
  const openElYear = Boolean(anchorElYear);
  const handleClickElYear = (event) => {
    setAnchorElYear(event.currentTarget);
  };
  const handleCloseElYear = () => {
    setAnchorElYear(null);
  };
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const handleSelectedYear = (itemName) => {
    setSelectedYear(itemName);
    handleCloseElYear();
  };
  // Month Menu
  const months = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  const [anchorElMonth, setAnchorElMonth] = useState(null);
  const openElMonth = Boolean(anchorElMonth);
  const handleClickElMonth = (event) => {
    setAnchorElMonth(event.currentTarget);
  };
  const handleCloseElMonth = () => {
    setAnchorElMonth(null);
  };
  // console.log(currentDate);
  // console.log(currentDate.getMonth());
  const [selectedMonth, setSelectedMonth] = useState(
    months[currentDate.getMonth()]
  );
  const handleSelectedMonth = (itemName) => {
    setSelectedMonth(itemName);
    handleCloseElMonth();
  };
  useEffect(() => {
    // console.log(categoryFilter);
    // console.log(selectedYear);
    // console.log(months.indexOf(selectedMonth) + 1);
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/dashboard/results?year=${selectedYear}&month=${
            months.indexOf(selectedMonth) + 1 < 9
              ? `${months.indexOf(selectedMonth) + 1}`
              : months.indexOf(selectedMonth) + 1
          }&${categoryFilter === CategoriesFilter[0] ? "" : "most_searched"}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        // console.log(response);
        setData(response?.data);
        // @ts-ignore
      } catch (err) {
        if (err?.response?.status === 401) {
          ChangeAuthInfo({});
          // @ts-ignore
          navigate("/");
        }
        // console.log(err);
      }
    }, 150);
  }, [categoryFilter, selectedYear, selectedMonth]);
  return (
    <div
      className="cat-container"
      style={{ display: "grid", backgroundColor: "#f1f1f1" }}
    >
      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "10px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4
          style={{
            color: "#1D3557",
            fontSize: "18px",
            marginRight: "5px",
          }}
        >
          لوحة القيادة
        </h4>
      </Form.Label>
      <Stack
        style={{ marginLeft: "40px" }}
        direction={"column"}
        rowGap={3}
        dir="rtl"
        sx={{ p: 5, m: 1, bgcolor: "white", borderRadius: 5 }}
      >
        <Stack direction={"row"} columnGap={2}>
          <Button
            id="year"
            aria-controls={openElYear ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openElYear ? "true" : undefined}
            disableElevation
            onClick={handleClickElYear}
            sx={{
              fontFamily: "Noto Sans Arabic",
              border: 1,
              borderColor: "#E7E7E7",
              bgcolor: "#FCFCFC",
              color: "#1D3557",
            }}
          >
            السنة {selectedYear}
            <KeyboardArrowDown sx={{ mr: 3, color: "#1D3557" }} />
          </Button>
          <Menu
            id="year"
            anchorEl={anchorElYear}
            open={openElYear}
            onClose={handleCloseElYear}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {years.map((itemName) => (
              <MenuItem
                key={itemName}
                onClick={() => handleSelectedYear(itemName)}
                sx={{
                  fontFamily: "Noto Sans Arabic",
                  color: "#1D3557",
                }}
              >
                {itemName}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "#457B9D", width: 2.5 }}
          />
          <Button
            id="month"
            aria-controls={openElMonth ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openElMonth ? "true" : undefined}
            disableElevation
            onClick={handleClickElMonth}
            sx={{
              fontFamily: "Noto Sans Arabic",
              border: 1,
              borderColor: "#E7E7E7",
              bgcolor: "#FCFCFC",
              color: "#1D3557",
            }}
          >
            {selectedMonth}
            <KeyboardArrowDown sx={{ mr: 3, color: "#1D3557" }} />
          </Button>
          <Menu
            id="month"
            anchorEl={anchorElMonth}
            open={openElMonth}
            onClose={handleCloseElMonth}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {months.map((itemName) => (
              <MenuItem
                key={itemName}
                onClick={() => handleSelectedMonth(itemName)}
                sx={{
                  fontFamily: "Noto Sans Arabic",
                  color: "#1D3557",
                }}
              >
                {itemName}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <Divider
          variant="inset"
          sx={{ bgcolor: "#457B9D", color: "#457B9D", py: 0.1, ml: 19 }}
        />
        <Section1 data={data?.section_1} />
        <Section2 data={data?.section_2} />
        <Section3
          data={data?.section_3}
          CategoriesFilter={CategoriesFilter}
          categoryFilter={categoryFilter}
          changeCategoryFilter={changeCategoryFilter}
        />
        <Section4 data={data?.section_4} />
      </Stack>
    </div>
  );
}
