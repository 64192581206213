// @ts-nocheck
import {
  Avatar,
  Box,
  Container,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import classes from "./Comment.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import Slide from "@mui/material/Slide";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Root = styled("div")(
  ({ theme }) => `
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td {
    border: 1px solid #457B9D;
    color: #1d3557;
    text-align: center;
    padding: 8px;
    font-size: 17px;
    font-weight: 700;
  }
  `
);
const Comment = (props) => {
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/comments/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.comment) {
          setComment(responseData.comment);
        }
      });
  }, []);
  // console.log(comment);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [acceptComment, setAcceptComment] = useState(false);
  const [rejectComment, setRejectComment] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const [error, setError] = useState("");
  const handleReject = () => {
    fetch(`https://api.whitesooq.com/api/v1/comments/unaccept/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        return response.json();
      })
      .then((data) => {
        // if (data.status) {
        //     setRejectComment(true);
        //     setOpen(false);
        //     navigate("/showComments-page");
        // }
        setOpen(false);
        if (data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ رفض التعليق بنجاح",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data?.message ? data?.message : "هناك مشكلة",
          });
        }
        setTimeout(() => {
          navigate("/showComments-page");
        }, 1000);
      })
      .catch((error) => {});
  };
  const handleAccept = () => {
    fetch(`https://api.whitesooq.com/api/v1/comments/accept/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        return response.json();
      })
      .then((data) => {
        // if (data.status) {
        //   setAcceptComment(true);
        //   navigate("/showComments-page");
        // }
        setOpen(false);
        if (data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ قبول التعليق بنجاح",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data?.message ? data?.message : "هناك مشكلة",
          });
        }
        setTimeout(() => {
          navigate("/showComments-page");
        }, 1000);
      })
      .catch((error) => {});
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4
          style={{
            color: "#1D3557",
            fontSize: "18px",
            marginRight: "5px",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/showComments-page"
          >
            التعليقات
          </Link>
          / تفاصيل التعليق
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: { xs: "16px 8px", sm: 2 },
            width: { xs: 375, sm: 650 },
            height: { xs: 570, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل التعليق
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: { xs: "16px 8px", sm: 2 },
              width: { xs: 360, sm: 560 },
              height: { xs: 480, sm: 600 },
            }}
          >
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/showComments-page")}
            >
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <FormControl sx={{ m: 1 }}>
                <Select
                  IconComponent={ExpandMore}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  autoWidth
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                >
                  <MenuItem
                    onClick={handleClickOpen}
                    value={"قبول"}
                    sx={{ pl: 6 }}
                  >
                    قبول
                  </MenuItem>
                  <MenuItem
                    onClick={handleClickOpen}
                    value={"رفض"}
                    sx={{ pl: 6 }}
                  >
                    رفض
                  </MenuItem>
                </Select>
              </FormControl>
              {(!acceptComment || !rejectComment) && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  {option === "رفض" ? (
                    <Close
                      sx={{
                        color: "red",
                        m: "20px auto 0",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  ) : (
                    <Check
                      sx={{
                        color: "green",
                        m: "20px auto 0",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  )}
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 50px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {`هل أنت متأكد من ${option} هذا التعليق ؟`}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    ></DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={option === "رفض" ? handleReject : handleAccept}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "5px auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "10px 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {error}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {(acceptComment || rejectComment) && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {`تم ${option} التعليق بنجاح`}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{
                color: "#1d3557",
                fontWeight: 800,
                fontSize: "25px",
              }}
            >
              عرض التعليق
            </Typography>
            <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
              <Root sx={{ maxWidth: "100%", width: "100%", mt: 1 }}>
                <table
                  className={classes.tableDetails}
                  aria-label="custom pagination table"
                >
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 400 }}>
                        <div className={classes.sentence1WithoutPadding}>
                          <div className={classes.flexTable}>
                            <IconButton
                              onClick={() =>
                                navigate(`/profile/${comment?.user_id}`)
                              }
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>
                            {/* <Typography>hfgh</Typography> */}
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "20px",
                              }}
                            >
                              {comment?.user ? comment?.user?.username : ""}
                            </Typography>
                            <Avatar
                              sx={{
                                ml: 1,
                                width: "40px",
                                height: "40px",
                                p: 0,
                              }}
                              src={
                                comment?.user
                                  ? comment?.user?.full_path_image
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td style={{ width: 120 }} align="right">
                        اسم المستخدم
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 400 }}>
                        {comment?.ad ? comment?.ad?.title : ""}
                      </td>
                      <td style={{ width: 120 }} align="right">
                        اسم الإعلان
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 400 }}>
                        {comment?.ad?.description}
                      </td>
                      <td style={{ width: 120 }} align="right">
                        وصف الإعلان
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 400 }}>
                        <div className={classes.sentence1WithoutPadding}>
                          <Rating
                            className={classes.flexTable}
                            name="read-only"
                            value={comment?.user ? comment?.user?.rating : 0}
                            readOnly
                          />
                        </div>
                      </td>
                      <td style={{ width: 120 }} align="right">
                        تقييم الإعلان
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: 400 }}>{comment.content}</td>
                      <td style={{ width: 120 }} align="right">
                        تعليق المستخدم
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Root>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default Comment;
