import React, { useContext, useEffect } from "react";
import {
  Alert,
  Form,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import arrow from "./../admin-image/user/arrow.svg";
import fac from "./../admin-image/user/fac.svg";
import camera from "./../admin-image/user/camera.svg";
import "./Editeuser.css";
// import '../Adduser/Adduser.css'
import axios from "axios";
import { useState } from "react";
import { AuthContext } from "store/UseAuthContext";
import { Link, useParams } from "react-router-dom";
import Snack from "components/Utilities/Snack";

export const Editeuser = () => {
  const { authInfo } = useContext(AuthContext);
  const params = useParams();
  const [username, setusername] = useState("");
  const [fullname, setfullname] = useState("");
  const [phone, setphone] = useState("");
  const [phonewhats, setphonewhats] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [codeWhats, setCodeWhats] = useState("");
  const [image, setImage] = useState(null);
  const [sImage, setSImage] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState("1");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const countries = [
    { name: "الأردن", code: "962" },
    { name: "الإمارات العربية المتحدة", code: "971" },
    { name: "البحرين", code: "973" },
    { name: "الجزائر", code: "213" },
    { name: "السعودية", code: "966" },
    { name: "السودان", code: "249" },
    { name: "الصومال", code: "252" },
    { name: "العراق", code: "964" },
    { name: "الكويت", code: "965" },
    { name: "المغرب", code: "212" },
    { name: "اليمن", code: "967" },
    { name: "تونس", code: "216" },
    { name: "جيبوتي", code: "253" },
    { name: "سوريا", code: "963" },
    { name: "عمان", code: "968" },
    { name: "فلسطين", code: "970" },
    { name: "قطر", code: "974" },
    { name: "لبنان", code: "961" },
    { name: "ليبيا", code: "218" },
    { name: "مصر", code: "20" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
    { name: "موريتانيا", code: "222" },
    { name: "تشاد", code: "235" },
  ];
  const handleCameraClick = () => {
    const imageInput = document.getElementById("imageInput");
    imageInput.click();
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Set the file to the state
      setSImage(URL.createObjectURL(file));
    }
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("fullname", fullname);
      if (username) {
        formData.append("username", username);
      }
      if (phone) {
        formData.append("phone_number", `${countryCode} ${phone}`); // Include country name
      }
      if (phonewhats) {
        formData.append("whatsapp", `${codeWhats} ${phonewhats}`); // Include country name
      }
      if (acceptTerms) {
        formData.append("accept_terms", acceptTerms);
      }
      if (image instanceof File) {
        formData.append("image", image);
      }
      console.log(formData);
      const response = await axios.post(
        `https://api.whitesooq.com/api/v1/users/${params.id}`,
        formData,
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      if (response?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: "تم تعديل تفاصيل المستخدم",
        });
      }
    } catch (err) {
      if (err.request.status === 403) {
        setAlert({
          open: true,
          type: "warning",
          message: "عذراً ليس لديك صلاحية",
        });
      } else if (err?.response?.status === 401) {
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "الخادم لا يستجيب",
        });
      }
    }
  };
  useEffect(() => {
    const showUser = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/users/profile/${params.id}`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        if (response.data.data.full_path_image) {
          setImage(response.data.data.full_path_image); // Set the image URL here
        }
        setCountryCode(response.data.data.country);
        setusername(response.data.data.username);
        setfullname(response.data.data.fullname);
      } catch (err) {
        console.log(err);
      }
    };
    showUser();
  }, [params.id, authInfo.token]);
  return (
    <div className="Editeuser adduser">
      <Snack alert={alert} />
      <section className="changeAlert">
        <Modal
          style={{ direction: "rtl" }}
          show={showAlert}
          variant="none"
          backdrop="static"
          centered
        >
          <ModalHeader>{message}</ModalHeader>
          <ModalFooter>
            <div className="d-flex justify-content-start">
              <Button
                style={{
                  backgroundColor: "#1D3557",
                  marginLeft: "180px",
                }}
                onClick={() => setShowAlert(false)}
                variant="none"
              >
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={`/edite-user-page-page/${params.id}`}
                >
                  موافق
                </Link>
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </section>
      <Container>
        <div className="contentadd" dir="rtl">
          <Link
            style={{ textDecoration: "none" }}
            to={`/activeUsers-page/${params.id}`}
          >
            <button style={{ border: "1px solid #FFF" }}>
              <img src={arrow} />
            </button>
          </Link>
          <br />
          <h6> تعديل المستخدم</h6>
        </div>
        <div className="inputimg">
          <input
            type="file"
            id="imageInput"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <img src={camera} onClick={handleCameraClick} className="img2" />
          <Avatar
            className="upload-img"
            sx={{ width: 225, height: 225 }}
            src={image}
            alt={username}
          />
          {/* <img
                        src={sImage ? sImage : image ? image : fac}
                        className="img1"
                    /> */}
        </div>
        <h5>
          المعرف الشخصي للحساب <span> {params.id}# </span>{" "}
        </h5>

        <Form.Label htmlFor="inputPassword5">اسم المستخدم </Form.Label>

        <Form.Control
          type="text"
          id="inputPassword5"
          aria-describedby="passwordHelpBlock"
          placeholder="Ranim01"
          required
          onChange={(e) => setusername(e.target.value)}
          value={username}
        />

        <br />

        <Form.Label htmlFor="inputPassword5">
          {" "}
          ادخل الاسم بالكامل <i style={{ color: "red" }}>*</i>
        </Form.Label>
        <Form.Control
          type="text"
          id="inputPassword5"
          aria-describedby="passwordHelpBlock"
          placeholder="رنيم احمد عمر"
          required
          onChange={(e) => setfullname(e.target.value)}
          value={fullname}
        />
        <br />
        <Form.Label htmlFor="inputPassword5">رقم الهاتف </Form.Label>
        <InputGroup className="mb-3" dir="rtl">
          <InputGroup className="mb-3" dir="rtl">
            <Form.Control
              placeholder="965455555"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
            <FormControl variant="outlined">
              <Select
                onChange={(e) => {
                  const selectedCountry = countries.find(
                    (c) => c.name === e.target.value
                  );
                  setCountryCode(`+${selectedCountry.code}`);
                }}
                label="Country"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name} (+{country.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputGroup>
        </InputGroup>
        <br />
        <Form.Label htmlFor="inputPassword5">
          رقم الهاتف الخاص بالواتساب
        </Form.Label>
        <InputGroup className="mb-3" dir="rtl">
          <InputGroup className="mb-3" dir="rtl">
            <Form.Control
              placeholder="965455555"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={phonewhats}
              onChange={(e) => setphonewhats(`${e.target.value}`)}
            />
            <FormControl variant="outlined">
              <Select
                onChange={(e) => {
                  const selectedCountry = countries.find(
                    (c) => c.name === e.target.value
                  );
                  setCodeWhats(`+${selectedCountry.code}`);
                }}
                label="Country"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name} (+{country.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputGroup>
          <br />
          <br />
        </InputGroup>
        <div className="adduseradd" dir="ltr">
          <button
            style={{ marginBottom: "8px", paddingTop: "5px" }}
            dir="ltr"
            className=""
            type="submit"
            onClick={handleClick}
          >
            {" "}
            تحديث{" "}
          </button>
        </div>
      </Container>
    </div>
  );
};
