import { Container, NavDropdown } from "react-bootstrap";
import points from "./../admin-image/categ/points.svg";
import search from "./../../../assest/images/Landingpagevisitor/searchbar/search.svg";
import { Categadmin } from "./../../Admin/Topcategory/Categadmin";

import { Box } from "@mui/material";

import classes from "../View/ViewOffer.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState, useContext } from "react";

import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const InsideTest = () => {
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [input, setinput] = useState(" ");
  const [testCaregory, setTestCaregory] = useState("");
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/messages/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.data) {
          setTestCaregory(responseData.data);
        }
      });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [testDeleted, setTestDeleted] = useState(false);
  const [error, setError] = useState("");
  const handleDelete = () => {
    fetch(`https://api.whitesooq.com/api/v1/messages/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setTestDeleted(true);
          setOpen(false);
          navigate("/messages-page");
        }
      })
      .catch((error) => setError(error.message));
  };

  return (
    <div className="inside" dir="rtl" style={{ marginTop: "-60px" }}>
      <Container>
        <Box className={classes.menuOption}>
          <FormControl sx={{ mb: 2 }}>
            <Select
              IconComponent={ExpandMore}
              value={option}
              onChange={handleChange}
              displayEmpty
              autoWidth
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem onClick={handleClickOpen} sx={{ pl: 6 }}>
                حذف
              </MenuItem>
            </Select>
          </FormControl>
          {!testDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {"هل انت متأكد من حذف هذه الفئة بشكل نهائي ؟"}
              </DialogTitle>

              <DialogActions sx={{ m: "0 auto" }}>
                <button
                  className={classes.btnCancelDialog}
                  onClick={handleClose}
                >
                  إلغاء
                </button>
                <button className={classes.btnOkDialog} onClick={handleDelete}>
                  متأكد
                </button>
              </DialogActions>
            </Dialog>
          )}
          {error && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {error}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Close sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  ok
                </button>
              </DialogActions>
            </Dialog>
          )}
          {testDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {"تم حذف الفئة بنجاح"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Check sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  موافق
                </button>
              </DialogActions>
            </Dialog>
          )}
        </Box>

        <h3 className="inside3">فئة تجريبية </h3>
        <h4 className="inside4">البحث عن أعلان</h4>
        <div className="col-lg-6">
          <form className="topcategsearch" dir="ltr">
            <img
              src={search}
              className="searchimg"
              style={{ marginLeft: "120px" }}
            />
            <input
              type="text"
              placeholder="..بحث"
              name=""
              value={input}
              onChange={(e) => {
                setinput(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="adsrelation d-flex">
          <div className="blueshape"></div>
          <h5>
            الإعلانات ذات الصلة بالفئة المحددة <span> (15 إعلان) </span>{" "}
          </h5>
        </div>
        <Categadmin />
      </Container>
    </div>
  );
};
