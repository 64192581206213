// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import "./PackageDetails.css";
import React from "react";
import Footer from "../../../layouts/Footer/Footer";
import Headerloginn from "../../../layouts/Headerlogin/Headerloginn";
import { useParams } from "react-router-dom";
import { WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PackageDetails = () => {
  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState([]);
  // console.log(data);
  const [error, setError] = useState("");
  const fetchPackageHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch(
        `https://api.whitesooq.com/api/v1/packages/${id}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الباقات يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      // console.log(data);
      await setData(data.package);
    } catch (error) {
      setError(error.message);
    }
  }, [id]);
  useEffect(() => {
    fetchPackageHandler();
  }, [fetchPackageHandler]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="detailss-package">
      <Headerloginn />
      <Container>
        <Col>
          <h2
            className="verr-packagee"
            style={{
              color: "#1D3557",
              marginTop: "96px",
              textAlign: "center",
            }}
          >
            {/* الباقة العامة */}
            {data ? data.name : ""}
          </h2>
        </Col>
        <Col>
          <h6
            className="descc-package"
            style={{
              color: "#1D3557",
              marginTop: "28px",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            {data ? data.description : ""}
            {/* باقة تفعيل افتراضية لجميع المستخدمين عند نشر أول إعلان لهم */}
          </h6>
        </Col>

        <Row dir="rtl">
          <Row>
            <Col>
              <Form.Label className="second-title defin-package">
                {/* معرف الباقة #012 */}
                معرف الباقة #{data ? data.id : ""}
              </Form.Label>
              <Form.Label className="second-title1">
                {/* نوع الباقة: عامة */}
                نوع الباقة:
                {data ? (data.type === "public" ? "عامة" : "خاصة") : ""}
              </Form.Label>
            </Col>
          </Row>

          <div
            className="col-md-6"
            style={{
              boxShadow: "0px 4px 25px 0px #41404033",
              marginBottom: "10px",
            }}
          >
            <div className="card" style={{ border: "1px solid #457B9D" }}>
              <div className="card-header">
                <h5 className="card-title" style={{ color: "#FFFFFF" }}>
                  تكلفة الباقة
                </h5>
                <h5 className="card-title" style={{ color: "#FFFFFF" }}>
                  {/* د.أ \ 25000 */}
                  {data?.id === 1 ? "مجانية" : ""}
                  {data?.id !== 1 ? " د.أ \\ " + data?.cost : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">صلاحية الباقة</h5>
                <h5 className="card-title">
                  {/* يوم واحد */}
                  {data ? data.validity : ""} أيام
                </h5>
              </div>
              <div
                className="card-header "
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">مدة صلاحية إعلانات البيع</h5>
                <h5 className="card-title" style={{ color: "#1D3557" }}>
                  {/* 3 أيام */}
                  {data ? data.sale_ads_validity : ""} أيام
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                {/* test */}
                <h5 className="card-title">حد نشر إعلانات البيع</h5>
                <h5 className="card-title">
                  {/* 5 مرات يوميا */}
                  {data ? data.sale_ads_limit : ""} إعلانات
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">سماحية تعديل إعلانات البيع</h5>
                <h5 className="card-title">
                  {/* نعم */}
                  {data ? (data.sale_ads_updateable === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">
                  سماحية الحصول على إحصائيات إعلانات البيع
                </h5>
                <h5 className="card-title">
                  {/* لا */}
                  {data ? (data.sale_ads_resultable === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">حد نشر العروض</h5>
                <h5 className="card-title">
                  {/* نعم */}
                  {data ? data.offers_limit : ""} عروض
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">خصومات الخدمات</h5>
                <h5 className="card-title">
                  %{data ? data.service_discounts : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">اخفاء قيمة العرض</h5>
                <h5 className="card-title">
                  {/* لا */}
                  {data ? (data.hide_offer === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">تثبيت الإعلانات</h5>
                <h5 className="card-title">
                  {/* لا */}
                  {data ? (data.pinable === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{ boxShadow: "0px 4px 25px 0px #41404033" }}
          >
            <div className="card" style={{ border: "1px solid #457B9D" }}>
              <div
                className="card-header"
                style={{
                  backgroundColor: "#457B9D",
                  alignItems: "center",
                  height: "57.19px",
                }}
              >
                <h5 className="card-title "></h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">مدة صلاحية تثبيت الإعلان</h5>
                <h5 className="card-title" style={{ color: "#1D3557" }}>
                  {/* 3 أيام */}
                  {data ? data.pinable_validity : ""} أيام
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">مدة صلاحية إعلانات الشراء</h5>
                <h5 className="card-title" style={{ color: "#1D3557" }}>
                  {/* 3 أيام */}
                  {data ? data.buy_ads_validity : ""} أيام
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">حد نشر إعلانات الشراء</h5>
                <h5 className="card-title">
                  {/* 5 مرات يومياً */}
                  {data ? data.buy_ads_limit : ""} إعلانات
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">
                  سماحية الحصول على إحصائيات إعلانات الشراء
                </h5>
                <h5 className="card-title">
                  {/* لا */}
                  {data ? (data.buy_ads_resultable === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE4D" }}
              >
                <h5 className="card-title">سماحية تعديل إعلانات الشراء</h5>
                <h5 className="card-title">
                  {/* نعم */}
                  {data ? (data.buy_ads_updateable === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "#DEEDEE" }}
              >
                <h5 className="card-title">تمييز العرض</h5>
                <h5 className="card-title">
                  {/* لا */}
                  {data ? (data.offer_highlighting === 0 ? "لا" : "نعم") : ""}
                </h5>
              </div>
              <div
                className="card-header"
                style={{ backgroundColor: "white", border: "1px solid white" }}
              ></div>
              <div
                className="card-header"
                style={{ backgroundColor: "white", border: "1px solid white" }}
              ></div>
              <div
                className="card-header"
                style={{ backgroundColor: "white", border: "1px solid white" }}
              ></div>
            </div>
          </div>
        </Row>
        <Col style={{ textAlign: "center" }}>
          <Button
            className="subs-package"
            type="submit"
            style={{
              border: "2px solid #457B9D",
              backgroundColor: "#457B9D",
              borderRadius: "10px",
              marginTop: "40px",
              marginBottom: "87px",
              cursor: "pointer",
            }}
            onClick={handleClickOpen}
          >
            اشتراك
          </Button>
          {
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 40px",
                  color: "#1d3557",
                  fontWeight: 600,
                }}
              >
                {`الرجاء التواصل مع المسؤول على الرقم`}
              </DialogTitle>
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 40px",
                  color: "#1d3557",
                  fontWeight: 600,
                }}
              >
                ({data.admin_number})
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{
                    color: "#457B9D",
                    textAlign: "center",
                    m: "10px auto",
                    p: "0 40px",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                  id="alert-dialog-slide-description"
                >
                  : وطلب منه تفعيل هذا الاشتراك من خلال المعلومات التالية
                </DialogContentText>
                <DialogContentText
                  sx={{
                    color: "#457B9D",
                    textAlign: "center",
                    m: "0 auto",
                    p: "0 40px",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                  id="alert-dialog-slide-description"
                >
                  معرف الباقة: #{data?.id}
                </DialogContentText>
                <DialogContentText
                  sx={{
                    color: "#457B9D",
                    textAlign: "center",
                    m: "0 auto",
                    p: "0 40px",
                    fontSize: "20px",
                    fontWeight: 600,
                    dir: "rtl",
                  }}
                  id="alert-dialog-slide-description"
                >
                  اسم الباقة: {data?.name}
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className="btnOkDialog" onClick={handleClose}>
                  موافق
                </button>
              </DialogActions>
            </Dialog>
          }
        </Col>
      </Container>
      <Footer />
    </div>
  );
};

export default PackageDetails;
