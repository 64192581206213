import { useContext, useEffect, useState } from "react";
import { Container, Image, Form } from "react-bootstrap";
import { Inside } from "./Inside";
import { Topcategory } from "./Topcategory";
import "./topcategory.css";
import Home from "./Home.svg";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
export const CategoryServices = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const params = useParams();
  const { authInfo } = useContext(AuthContext);
  useEffect(() => {
    const categ = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/categories/${params.id}`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        setTitle(response.data.data[0].name);
        setData(response.data.data[0].ads);
      } catch (err) {
        console.log(err);
      }
    };
    categ();
  }, []);
  return (
    <div style={{ backgroundColor: "#F2F2F2" }}>
      <div className="homing d-flex" dir="rtl">
        <Form.Label
          style={{
            display: "flex",
            direction: "rtl",
            alignItems: "flex-start",
            marginRight: "25px",
            marginTop: "16px",
          }}
        >
          <Image style={{ marginTop: "-2px" }} src={Home} />
          <h4
            style={{
              color: "#1D3557",
              fontSize: "18px",
              marginRight: "5px",
            }}
          >
            الفئات{" "}
            {params.type === "deleted" ? (
              <Link
                to={"/DeletedData-page"}
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginRight: "5px",
                  fontFamily: "inherit",
                }}
              >
                المحذوفة
              </Link>
            ) : (
              <Link
                to={"/nonDeletedData-page"}
                style={{
                  color: "#1D3557",
                  fontSize: "18px",
                  marginRight: "5px",
                  fontFamily: "inherit",
                }}
              >
                الغير المحذوفة
              </Link>
            )}
            /{title}
          </h4>
        </Form.Label>
      </div>
      <Container
        style={{
          borderRadius: "8px",
          background: "#FFF",
          padding: "40px",
        }}
      >
        <Topcategory />
        <Inside
          title={title}
          setTitle={setTitle}
          data={data}
          setData={setData}
        />
      </Container>
    </div>
  );
};
