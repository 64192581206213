// @ts-nocheck
import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Col,
  Image,
  Modal,
  Alert,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import Home from "./../AddCategory/Home.svg";
import { useNavigate, Link } from "react-router-dom";
import Arrow from "./../AddCategory/Arrow.svg";
import Currencies from "./../../../Files/Currencies.json";
import Essentialicons from "./../View/Essentialicons.svg";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import "./payment.css";
import Snack from "components/Utilities/Snack";

const AddPayment = () => {
  const navigate = useNavigate();
  const { authInfo } = useContext(AuthContext);

  // State variables
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [userId, setUserId] = useState("");
  const [packageId, setPackageId] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showIcon, setShowIcon] = useState();
  const [userPay, setUserPay] = useState({
    user_id: "",
    package_id: "",
    amount: "",
    currency: "",
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setUserPay({
      user_id: userId,
      package_id: packageId,
      amount: amount,
      currency: currency,
    });
    console.log(userPay);
  };

  // Handle adding payment
  const handleAddPayment = () => {
    setShowIcon(<Image src={Essentialicons} />);
    setAlertText("هل انت متأكد من اضافة الدفعة");
    setShowModal(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  // Handle confirming the action
  const handleConfirm = async () => {
    setShowModal(false);
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("package_id", packageId);
      formData.append("amount", amount);
      formData.append("currency", currency);
      const response = await axios.post(
        `https://api.whitesooq.com/api/v1/payments`,
        formData,
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      if (response?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: "تمت اضافة الدفعة بنجاح",
        });
      }
      console.log(response);
      setUserId("");
      setPackageId("");
      setAmount("");
      setCurrency("");
    } catch (err) {
      if (err.request.status === 403) {
        setAlert({
          open: true,
          type: "warning",
          message: "عذراً ليس لديك صلاحية",
        });
      } else if (err?.response?.status === 401) {
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "الخادم لا يستجيب",
        });
      }
    }
  };

  // Handle canceling the action
  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div
      className="addpayment-pagee"
      style={{ display: "grid", backgroundColor: "#f1f1f1" }}
    >
      <Snack alert={alert} />
      <section className="changeAlert">
        <Modal
          show={showAlert}
          variant="none"
          style={{ direction: "rtl" }}
          backdrop="static"
          centered
        >
          <ModalHeader>{message}</ModalHeader>
          <ModalFooter className="d-flex justify-content-start">
            <Button
              style={{
                backgroundColor: "#1D3557",
                marginRight: "180px",
              }}
              onClick={() => setShowAlert(false)}
              variant="none"
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
                to="/add-payment-page"
              >
                Agree
              </Link>
            </Button>
          </ModalFooter>
        </Modal>
      </section>
      <Link
        style={{
          textDecoration: "none",
          fontFamily: "inherit",
          color: "inherit",
          cursor: "pointer",
        }}
        to="/paymentData-page"
      >
        <Form.Label
          style={{
            display: "flex",
            direction: "rtl",
            alignItems: "flex-start",
            marginRight: "10px",
            marginTop: "16px",
          }}
        >
          <Image style={{ marginTop: "-2px" }} src={Home} />
          <h4
            style={{
              color: "#1D3557",
              fontSize: "18px",
              marginRight: "5px",
            }}
          >
            الدفعات
          </h4>
        </Form.Label>
      </Link>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          marginRight: "17px",
          marginLeft: "93px",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        }}
      >
        <Form
          dir="rtl"
          onClick={handleSubmit}
          style={{
            width: "95%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginTop: "73px",
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/paymentData-page">
            <Col style={{ marginRight: "-15px" }}>
              <Button
                onClick={() => navigate("")}
                style={{
                  backgroundColor: "white",
                  border: "1px solid white",
                }}
              >
                <Image src={Arrow} />
              </Button>
            </Col>
          </Link>

          <Col>
            <h2
              style={{
                color: "#1D3557",
                fontSize: "35px",
                marginTop: "16px",
              }}
            >
              إضافة دفعة
            </h2>
          </Col>

          <Form.Group controlId="userId">
            <Form.Label
              style={{
                color: "#1D3557",
                fontSize: "18px",
                marginTop: "16px",
              }}
            >
              معرف المستخدم <i style={{ color: "red" }}>*</i>
            </Form.Label>
            <Form.Control
              type="number"
              value={userId}
              placeholder="450"
              onChange={(e) => setUserId(e.target.value)}
              required
              style={{ border: "1px solid #457B9D" }}
            />
          </Form.Group>

          <Form.Group controlId="packageId">
            <Form.Label
              style={{
                color: "#1D3557",
                fontSize: "18px",
                marginTop: "16px",
              }}
            >
              معرف الباقة <i style={{ color: "red" }}>*</i>
            </Form.Label>
            <Form.Control
              type="number"
              value={packageId}
              placeholder="121"
              onChange={(e) => setPackageId(e.target.value)}
              required
              style={{ border: "1px solid #457B9D" }}
            />
          </Form.Group>

          <Form.Group controlId="amount">
            <Form.Label
              style={{
                color: "#1D3557",
                fontSize: "18px",
                marginTop: "16px",
              }}
            >
              المبلغ المدفوع <i style={{ color: "red" }}>*</i>
            </Form.Label>
            <Form.Control
              type="number"
              value={amount}
              placeholder="1000"
              onChange={(e) => setAmount(e.target.value)}
              required
              style={{ border: "1px solid #457B9D" }}
            />
          </Form.Group>

          <Form.Group controlId="currency">
            <Form.Label
              style={{
                color: "#1D3557",
                fontSize: "18px",
                marginTop: "16px",
              }}
            >
              نوع العملة <i style={{ color: "red" }}>*</i>
            </Form.Label>
            <Form.Select
              name="currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              style={{
                border: "1px solid #457B9D",
                height: "57px",
                borderRadius: "9px",
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "15px",
              }}
            >
              {Object.entries(Currencies).map((Currency) => (
                <option key={Currency[0]}> {Currency[1]} </option>
              ))}
              {currency === "" && (
                <p
                  style={{
                    color: "#E63946",
                    fontSize: "10px",
                    marginTop: "0",
                  }}
                >
                  الرجاء الاختيار
                </p>
              )}
            </Form.Select>
          </Form.Group>

          <Col style={{ direction: "ltr" }}>
            <Button
              type="submit"
              onClick={handleAddPayment}
              style={{
                border: "2px solid #457B9D",
                backgroundColor: "#457B9D",
                height: "60px",
                borderRadius: "10px",
                marginTop: "16px",
                marginBottom: "30px",
                cursor: "pointer",
                width: "290px",
                boxShadow: "0px 4px 19px 0px #7793414D",
              }}
            >
              <Link
                style={{
                  color: "#FFFFFF",
                  fontSize: "22px",
                  textDecoration: "none",
                }}
                to=""
              >
                إضافة
              </Link>
            </Button>
          </Col>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Body style={{ textAlign: "center" }}>
              {showIcon}
              <div
                style={{
                  color: "#1D3557",
                  fontSize: "24px",
                  fontFamily: "Noto Sans Arabic",
                  marginTop: "16px",
                }}
              >
                {alertText}
                <br />
                <p
                  style={{
                    color: "#457B9D",
                    fontSize: "17px",
                  }}
                >
                  {" "}
                  لا يمكن استعادة التعديل عند الإضافة
                </p>
              </div>
            </Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleConfirm}
                style={{
                  alignItems: "center",
                  width: "126px",
                  padding: "6px",
                  justifyContent: "center",
                  backgroundColor: "#457B9D",
                  fontSize: "24px",
                  height: "50px",
                  marginLeft: "20px",
                }}
              >
                متأكد
              </Button>
              <Button
                onClick={handleCancel}
                style={{
                  alignItems: "center",
                  width: "126px",
                  padding: "6px",
                  justifyContent: "center",
                  backgroundColor: "white",
                  color: "#1D3557",
                  border: "1px solid #1D3557",
                  fontSize: "24px",
                  height: "50px",
                }}
              >
                إلغاء
              </Button>
            </div>
          </Modal>
        </Form>
      </div>
    </div>
  );
};

export default AddPayment;
