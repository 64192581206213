// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import "./UserSupscription.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
const UserSubscription = () => {
  const { authInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptions_count, setsubscriptions_count] = useState("");
  useEffect(() => {
    axios
      .get("https://api.whitesooq.com/api/v1/users/subscriptions", {
        headers: {
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          authorization: "Bearer " + authInfo.token,
        },
      })
      .then((response) => {
        setSubscriptions(response.data.subscriptions);
        setsubscriptions_count(response.data.subscriptions_count);
        console.log(response.data);
      })
      .catch((Error) => console.log(Error));
  }, []);

  const iconFormatterShowSubscription = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <div className="showSubscription">
        <button onClick={() => navigate(`/packages/${row.id}`)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
          >
            <path
              d="M1.25 15.5C1.25 15.5 6.25 5.5 15 5.5C23.75 5.5 28.75 15.5 28.75 15.5C28.75 15.5 23.75 25.5 15 25.5C6.25 25.5 1.25 15.5 1.25 15.5Z"
              stroke="#1D3557"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 19.25C17.0711 19.25 18.75 17.5711 18.75 15.5C18.75 13.4289 17.0711 11.75 15 11.75C12.9289 11.75 11.25 13.4289 11.25 15.5C11.25 17.5711 12.9289 19.25 15 19.25Z"
              stroke="#1D3557"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };
  const iconFormattersubscription_status = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <div>
        {row.subscription_status === 1 ? (
          <p style={{ color: "green" }}> فعال </p>
        ) : (
          <p style={{ color: "red" }}> فعال غير </p>
        )}
      </div>
    );
  };

  const subscriptionsColumns = [
    {
      dataField: "",
      text: "",
      formatter: iconFormatterShowSubscription,
    },
    {
      dataField: "subscription_status",
      text: "حالة الاشتراك",
      formatter: iconFormattersubscription_status,
    },
    {
      dataField: "expiry_at",
      text: "تاريخ انتهاء الاشتراك",
    },
    {
      dataField: "created_at",
      text: "تاريخ بداية الاشتراك",
    },
    {
      dataField: "type",
      text: "نوع الباقة",
    },
    {
      dataField: "name",
      text: "اسم الباقة",
    },
    {
      dataField: "id",
      text: "معرف الباقة",
    },
  ];

  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1468_1183)">
        <path
          d="M18 6L6 18"
          stroke="#1D3557"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="#1D3557"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1468_1183">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div className="userSubscription">
      <div className="userSubscriptionContent">
        <nav>
          <div onClick={() => navigate("/")}>{closeIcon}</div>
          <h1> اشتراكات الحساب الشخصي</h1>
        </nav>

        <div>
          <h2> . عرض {subscriptions_count}</h2>
          <span className=" subscriptionsTable table-responsive-xl">
            <BootstrapTable
              keyField="id"
              columns={subscriptionsColumns}
              data={subscriptions}
              pagination={paginationFactory({
                nextPageText: "التالي",
                prePageText: "السابق",
              })}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserSubscription;
