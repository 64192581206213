// @ts-nocheck
import React, { useContext, useState } from "react";
import "./OfferChoice.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container } from "@mui/material";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";
const OfferChoice = (props) => {
  const { authInfo } = useContext(AuthContext);
  const [content, setContent] = useState("");
  const [value, setValue] = useState("");
  const [comment, setComment] = useState({
    ad_id: props.id,
    content: "",
  });
  const [Offer, setOffer] = useState({
    ad_id: props.id,
    content: "",
    value: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  const handleContent = (e) => {
    setContent(e.target.value);
    setComment({ ...comment, content });
  };
  const handleOffer = (e) => {
    setValue(e.target.value);
    setOffer({ ...Offer, content, value });
    console.log(Offer);
  };

  const AddComment = (e) => {
    e.preventDefault();
    axios
      .post(`https://api.whitesooq.com/api/v1/comments`, comment, {
        headers: {
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          authorization: "Bearer " + authInfo.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAlert({
          open: true,
          type: "success",
          message: "تمَّ العملبة بنجاح",
        });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول",
          });
        }
      });
  };

  const AddOffer = (e) => {
    e.preventDefault();
    axios
      .post(`https://api.whitesooq.com/api/v1/offers`, Offer, {
        headers: {
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          authorization: "Bearer " + authInfo.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response?.data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّت العملية بنجاح",
          });
        } else if (response?.data?.status === 0) {
          setAlert({
            open: true,
            type: "warning",
            message: response?.data?.message,
          });
        }
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول",
          });
        } else if (Error?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: Error?.response?.data?.message,
          });
        } else if (Error?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "الخادم لا يستجيب",
          });
        }
      });
  };

  return (
    <>
      <Snack alert={alert} />
      <Container>
        <div
          dir="rtl"
          className={` offerchoice ${
            props.type === "comment" ? "addcomment" : ""
          } `}
        >
          <Form className="offer">
            <Form.Group className="mb-3" id="exampleForm.ControlTextarea1">
              <Form.Label>
                {" "}
                {props.type === "comment"
                  ? "اكتب تعليقك هنا"
                  : "وصف العرض"}{" "}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder={
                  props.type === "comment"
                    ? "  تعليق ..."
                    : "وصف العرض المقدم ... "
                }
                onChange={handleContent}
                onMouseLeave={handleContent}
              />
            </Form.Group>
            {props.type === "comment" ? (
              <></>
            ) : (
              <span className="input">
                <Form.Group className="mb-3" id="1">
                  <Form.Label>السعر المقدم</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=" السعر ...  "
                    onChange={handleOffer}
                    onMouseLeave={handleOffer}
                  />
                </Form.Group>
              </span>
            )}
            <Button
              variant="primary"
              onClick={props.type === "comment" ? AddComment : AddOffer}
            >
              {props.type === "comment" ? "إضافة تعليق" : " تقديم عرض"}
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default OfferChoice;
