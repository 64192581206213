// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Image,
  Modal,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import Ellipse from "../../../../assest/images/SettingAccount/ViewProfile/Ellipse 2.png";
import Vector from "../../../../assest/images/SettingAccount/ViewProfile/Vector.svg";
import Vector1 from "../../../../assest/images/SettingAccount/ViewProfile/Vector (2).svg";
import star from "../../../../assest/images/SettingAccount/ViewProfile/Vector (3).svg";
import Snack from "components/Utilities/Snack";
import "./ViewProfile.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Userplus from "../../../../assest/images/SettingAccount/ViewProfile/user-plus.svg";
import Userminus from "../../../../assest/images/SettingAccount/ViewProfile/user-minus.svg";

import Phonecall from "../../../../assest/images/SettingAccount/ViewProfile/phone-call.svg";
import Whatsapp from "../../../../assest/images/SettingAccount/ViewProfile/whatsapp.svg";
import Flag from "../../../../assest/images/SettingAccount/ViewProfile/flag.svg";
import Messagecircle from "../../../../assest/images/SettingAccount/ViewProfile/message-circle.svg";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import Headerlogin from "layouts/Headerlogin/Headerlogin";
import Footer from "layouts/Footer/Footer";
import Googleplay from "../../../../assest/images/SettingAccount/ViewProfile/Googleplay.png";
import Appstore from "../../../../assest/images/SettingAccount/ViewProfile/Appstore.png";
import { Avatar, Icon, IconButton, Rating, Typography } from "@mui/material";
import { Container } from "@mui/system";

const ViewProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  console.log(params);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const [dataUser, setDataUser] = useState({});
  const [statusFollow, setStatusFollow] = useState(true);
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const whatsappLink = `https://api.whatsapp.com/send?phone=${dataUser.whatsapp}`;
  console.log(statusFollow);
  // Function to fetch user profile data
  const profile = async () => {
    try {
      const response = await axios.get(
        `https://api.whitesooq.com/api/v1/users/profile/${params.id}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      console.log(response);
      setDataUser(response.data.data);
      setStatusFollow(response.data.data.follow_btn);
    } catch (err) {
      console.log(err);
    }
  };
  // Function to unfollow a user
  const unFollowUser = async () => {
    try {
      const res = await axios.delete(
        `https://api.whitesooq.com/api/v1/unfollow-user/${params.id}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      console.log(res);
      setStatusFollow(true);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to follow a user
  const followUser = async () => {
    try {
      const res = await axios.post(
        `https://api.whitesooq.com/api/v1/follow-user/${params.id}`,
        {},
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      console.log(res);
      setStatusFollow(false);
    } catch (err) {
      console.log(err);
    }
  };
  const evaluate = async (value) => {
    try {
      const res = await axios.post(
        `https://api.whitesooq.com/api/v1/ratings`,
        { rateable_id: params.id, rateable_type: "user", value: value },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      console.log(res);
      if (res?.data?.status === 1) {
        setAlert({
          open: true,
          type: "success",
          message: "تمَّ التقييم بنجاح",
        });
      } else if (res?.data?.status === 0) {
        setAlert({
          open: true,
          type: "warning",
          message: res?.data?.message,
        });
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        setAlert({
          open: true,
          type: "error",
          message: "انتهت صلاحية تسجيل الدخول",
        });
        navigate("/");
      } else if (err?.response?.status === 422) {
        setAlert({
          open: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      } else if (err?.message === "Network Error") {
        setAlert({
          open: true,
          type: "warning",
          message: "مشكلة في الشبكة",
        });
      } else {
        setAlert({
          open: true,
          type: "warning",
          message: "الخادم لا يستجيب",
        });
      }
    }
  };
  useEffect(() => {
    console.log(authInfo.user?.id);
    console.log(params.id);
    if (authInfo.user?.id == params.id) {
      navigate("/statics");
    } else {
      profile();
    }
  }, [alert]);

  const [showAlert, setShowAlert] = useState(false);

  const handleClick = () => {
    setShowAlert(true);
  };

  return (
    <div>
      <Snack alert={alert} />
      <Headerlogin />
      <div className="view-profile" dir="rtl">
        <Container>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 boox1">
              <Avatar
                sx={{
                  marginLeft: "1px",
                  ml: 1,
                  width: "40px",
                  height: "40px",
                  p: 0,
                }}
                className="res-image"
                src={dataUser?.full_path_image}
              />

              <h4 className="info-username">{dataUser.username}</h4>
              <h3 className="info-username">{dataUser.fullname}</h3>
              <h4 className="info-username" style={{ direction: "rtl" }}>
                رقم واتساب{dataUser.whatsapp}{" "}
              </h4>
              <div style={{ display: "flex" }}>
                <div className="info-rate d-flex">
                  <h4>
                    <span style={{ marginLeft: "6px" }}>
                      {dataUser.followers_count}
                    </span>
                    متابع
                  </h4>
                  <img
                    className="rraattii"
                    src={dataUser.rating <= 1 ? star : Vector1}
                    onClick={() => evaluate(1)}
                  />
                  <img
                    className="rraattii"
                    src={dataUser.rating <= 2 ? star : Vector1}
                    onClick={() => evaluate(2)}
                  />
                  <img
                    className="rraattii"
                    src={dataUser.rating <= 3 ? star : Vector1}
                    onClick={() => evaluate(3)}
                  />
                  <img
                    className="rraattii"
                    src={dataUser.rating <= 4 ? star : Vector1}
                    onClick={() => evaluate(4)}
                  />
                  <img
                    className="rraattii"
                    src={dataUser.rating <= 5 ? star : Vector1}
                    onClick={() => evaluate(5)}
                  />
                  <span>({dataUser.rating_count})</span>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 boox2">
              <div className="user-num d-flex">
                <div>
                  <span>{dataUser.ads_count}</span>
                  <h3>عدد إعلانات المستخدم</h3>
                </div>
                <div>
                  <span>{dataUser.offers_count} </span>
                  <h3>عدد عروض المستخدم</h3>
                </div>
                <div>
                  <span>{dataUser.comments_count} </span>
                  <h3>عدد تعليقات المستخدم</h3>
                </div>
              </div>
              <div className="user-num2 d-flex">
                <Container className="bottonss">
                  <div onClick={statusFollow ? followUser : unFollowUser}>
                    <IconButton
                      type="button"
                      className="edit-button"
                      style={{
                        backgroundColor: statusFollow ? "#457B9D" : "white",
                        color: !statusFollow ? "#457B9D" : "white",
                        marginBottom: "32px",
                      }}
                    >
                      <Avatar
                        style={{ marginLeft: "10px" }}
                        src={statusFollow ? Userplus : Userminus}
                      />
                      <Link
                        style={{
                          textDecoration: "none",
                          color: !statusFollow ? "#457B9D" : "white",
                        }}
                        to=""
                      >
                        {statusFollow ? "متابعة" : "إلغاء متابعة"}{" "}
                      </Link>
                    </IconButton>
                  </div>

                  <Container className="buttonss">
                    <div
                      className="d-flex conecctiont"
                      style={{ marginRight: "-22px" }}
                    >
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`tel:${dataUser.phone_number}`}
                        >
                          <IconButton className="conect-profile">
                            <Avatar
                              style={{
                                marginLeft: "10px",
                              }}
                              src={Phonecall}
                            />{" "}
                            اتصال
                          </IconButton>
                        </a>
                      </div>

                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`sms:${dataUser.phone_number}`}
                        >
                          <IconButton
                            className="conect-profile"
                            style={{
                              backgroundColor: "#F1FAEE",
                            }}
                          >
                            <Avatar
                              style={{
                                marginLeft: "10px",
                              }}
                              src={Messagecircle}
                            />{" "}
                            مراسلة
                          </IconButton>
                        </a>
                      </div>
                      <div>
                        <a href={whatsappLink} target="_blank">
                          <IconButton className="conect-profile">
                            <Avatar
                              style={{
                                marginLeft: "10px",
                              }}
                              src={Whatsapp}
                            />{" "}
                            واتساب
                          </IconButton>
                        </a>
                      </div>
                    </div>
                  </Container>
                  <div>
                    <IconButton
                      type="button"
                      className="edit-button editt"
                      style={{
                        backgroundColor: "#F9F9F9",
                      }}
                    >
                      <Avatar style={{ marginLeft: "10px" }} src={Flag} />
                      <Link
                        style={{
                          color: "#1D3557",
                          textDecoration: "none",
                        }}
                        to={`/contact/with-admin/user/${dataUser?.id}`}
                      >
                        الإبلاغ عن إساءة
                      </Link>
                    </IconButton>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="downloadiiimage d-flex">
        <img src={Appstore} onClick={handleClick} />
        <Modal
          show={showAlert}
          onHide={() => setShowAlert(false)}
          style={{ direction: "rtl" }}
          backdrop="static"
          centered
        >
          <ModalHeader>
            <Modal.Title className="nneext">
              انتظرونا في الإصدارات القادمة
            </Modal.Title>
          </ModalHeader>
          <ModalFooter>
            <Button
              className="nneext-button"
              variant="secondary"
              onClick={() => setShowAlert(false)}
              style={{
                backgroundColor: "#1D3557",
              }}
            >
              إغلاق
            </Button>
          </ModalFooter>
        </Modal>

        <Link
          className="lin-footer"
          target="_blank"
          to="https://play.google.com/store/apps/details?id=online.whitesooq"
          style={{ textDecoration: "none" }}
        >
          <img className="ererrre" src={Googleplay} />
        </Link>
      </div>

      <Footer />
    </div>
  );
};

export default ViewProfile;
