import "./ContactUs.css";
import { Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";

const ContactUs = () => {
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  // @ts-ignore
  const navigate = useNavigate();
  const { type, reportable_type, reportable_id } = useParams();
  console.log({ type, reportable_type, reportable_id });
  //contact/us
  //contact/with-admin/id/type
  const [formData, setFormData] = useState({});
  const ChangeFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    // @ts-ignore
    if (
      type !== "us" &&
      type !== "with-admin" &&
      reportable_type !== "user" &&
      reportable_type !== "ad"
    ) {
      console.log(type === "us");
      console.log(type === "with-admin");
      navigate("/");
    }
  }, []);
  const handleSubmit = async () => {
    if (Object.keys(authInfo).length === 0) {
      setAlert({
        open: true,
        type: "error",
        message: "يرجى التاكد من تسجيل الدخول",
      });
      return;
    }
    if (type === "with-admin") {
      console.log(formData);
      // @ts-ignore
      const hasEmptyFields = "content" === "" ? true : false;
      if (!hasEmptyFields) {
        try {
          const response = await axios.post(
            `https://api.whitesooq.com/api/v1/reports`,
            {
              reportable_type,
              reportable_id,
              // @ts-ignore
              reason: formData.content,
            },
            {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${authInfo.token}`,
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              },
            }
          );
          console.log(response);
          if (response?.data?.status === 1) {
            setAlert({
              open: true,
              type: "success",
              message: "تمَّ إرسال الإبلاغٍ",
            });
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else if (response?.data?.status === 0) {
            setAlert({
              open: true,
              type: "warning",
              message: response?.data?.message,
            });
          }
        } catch (err) {
          if (err?.response?.status === 401) {
            ChangeAuthInfo({});
            setAlert({
              open: true,
              type: "error",
              message: "يرجى التاكد من تسجيل الدخول",
            });
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else if (err?.response?.status === 422) {
            setAlert({
              open: true,
              type: "error",
              message: err?.response?.data?.message,
            });
          } else if (err?.message === "Network Error") {
            setAlert({
              open: true,
              type: "warning",
              message: "مشكلة في الشبكة",
            });
          } else {
            setAlert({
              open: true,
              type: "warning",
              message: "الخادم لا يستجيب",
            });
          }
        }
      }
    } else {
      console.log(formData);
      const hasEmptyFields = ["fname", "lname", "phone_number", "content"].some(
        (fieldName) => !formData[fieldName]
      );
      if (!hasEmptyFields) {
        try {
          const response = await axios.post(
            `https://api.whitesooq.com/api/v1/messages`,
            formData,
            {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${authInfo.token}`,
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              },
            }
          );
          console.log(response);
          if (response?.data?.status === 1) {
            setAlert({
              open: true,
              type: "success",
              message: "تمَّ إرسال الرسالة",
            });
            setTimeout(() => {
              navigate("/");
            }, 500);
          } else if (response?.data?.status === 0) {
            setAlert({
              open: true,
              type: "warning",
              message: response?.data?.message,
            });
          }
        } catch (err) {
          if (err?.response?.status === 401) {
            ChangeAuthInfo({});
            setAlert({
              open: true,
              type: "error",
              message: "يرجى التاكد من تسجيل الدخول",
            });
            setTimeout(() => {
              navigate("/");
            }, 500);
          } else if (err?.response?.status === 422) {
            setAlert({
              open: true,
              type: "error",
              message: err?.response?.data?.message,
            });
          } else if (err?.message === "Network Error") {
            setAlert({
              open: true,
              type: "warning",
              message: "مشكلة في الشبكة",
            });
          } else {
            setAlert({
              open: true,
              type: "warning",
              message: "الخادم لا يستجيب",
            });
          }
        }
      }
    }
  };
  return (
    <>
      <Snack alert={alert} />
      <div className="chat-admin" dir="rtl">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className=" d-flex flex-column ">
              <span>
                <Link
                  to={
                    type === "us" ? "/" : reportable_type === "ads" ? "/" : "/" //reportable_type === "user"
                  }
                >
                  {closeIcon}
                </Link>
              </span>
              {type === "with-admin" ? (
                <Form.Text className="form-text">
                  واجهتك مشكلة او إساءة وتريد تقديم تقرير ابلاغ إلينا، يمكنك ترك
                  رسالة هنا وسنرد عليك في اقرب وقت.
                </Form.Text>
              ) : (
                <div className="contact-names">
                  <div className="contactus ms-4 mb-4">
                    <Form.Control
                      placeholder={`الاسم الأول *`}
                      name="fname"
                      onChange={ChangeFormData}
                    />
                  </div>
                  <div className="contactus ms-4 mb-4">
                    <Form.Control
                      name="lname"
                      onChange={ChangeFormData}
                      placeholder={`الاسم الأخير *`}
                    />
                  </div>
                  <div className="contactus ">
                    <Form.Control
                      name="phone_number"
                      onChange={ChangeFormData}
                      placeholder={`رقم الهاتف *`}
                    />
                  </div>
                </div>
              )}
              <Form.Control
                style={{
                  borderRadius: "4px",
                  backgroundColor: " #F2F2F2",
                }}
                as="textarea"
                rows={12}
                name="content"
                onChange={ChangeFormData}
                placeholder="اترك لي رسالة هنا ..."
              />
            </div>
          </Form.Group>
          <Button
            style={{
              textDecoration: "none",
              color: "white",
              width: "150px",
              height: "56px",
              padding: "10px 22px",
              whiteSpace: "nowrap",
            }}
            onClick={handleSubmit}
          >
            {type === "us" ? (
              <>{messageIcon} ارسل رسالة</>
            ) : (
              <>{flagIcon} ابلاغ</>
            )}
          </Button>
        </Form>
      </div>
    </>
  );
};
export default ContactUs;
const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="x">
      <path
        id="Vector"
        d="M18 6L6 18"
        stroke="#1D3557"
        stroke-width="2"
        troke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M6 6L18 18"
        stroke="#1D3557"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
const flagIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="flag">
      <path
        id="Vector"
        d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15Z"
        stroke="#F1FAEE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M4 22V15"
        stroke="#F1FAEE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19 9.50003C19.0034 10.8199 18.6951 12.1219 18.1 13.3C17.3944 14.7118 16.3098 15.8992 14.9674 16.7293C13.6251 17.5594 12.0782 17.9994 10.5 18C9.18013 18.0035 7.87812 17.6951 6.7 17.1L1 19L2.9 13.3C2.30493 12.1219 1.99656 10.8199 2 9.50003C2.00061 7.92179 2.44061 6.37488 3.27072 5.03258C4.10083 3.69028 5.28825 2.6056 6.7 1.90003C7.87812 1.30496 9.18013 0.996587 10.5 1.00003H11C13.0843 1.11502 15.053 1.99479 16.5291 3.47089C18.0052 4.94699 18.885 6.91568 19 9.00003V9.50003Z"
      stroke="#F1FAEE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
