import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";

import insta from "../../assest/images/Layouts/Footer/insta.svg";

import face from "../../assest/images/Layouts/Footer/face.svg";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <Container>
                <div className="row logo">
                    <h3 className="arabic">السوق الأبيض</h3>
                    <h3 className="english">WhiteSooq</h3>
                </div>

                <div className="row" style={{flexDirection: 'row-reverse'}}>

                <div className="col-lg-3 col-md-6 col-sm-12 explain">
                        <h4>
                            <span>السوق الأبيض </span>
                            هو موقع إلكتروني وتطبيق موبايل يعمل كمنصة لبيع وشراء
                            الإعلانات <span className="present-foo">وتقديم وعرض الخدمات </span>وطلب وعرض الوظائف في
                            الأردن بشكل خاص وفي الوطن العربي بشكل عام{" "}
                        </h4>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <ul className="footer-mar">
                            <li className="user-utlize">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/signUp/privacyAndPolicy"
                                >
                                    شروط وأحكام الاستخدام
                                </Link>
                            </li>
                            <li>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/signUp/privacyAndPolicy"
                                >
                                    سياسة الخصوصية
                                </Link>
                            </li>
                            <li className="user-utlize">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/signUp/privacyAndPolicy"
                                >
                                    اتفاقية ملفات تعريف الارتباط
                                </Link>
                            </li>
                            <li>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/contact/us"
                                >
                                    اتصل بنا
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 mmaa">
                        <ul className="footer-pack">
                            <li className="footer-log">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/login"
                                >
                                    تسجيل دخول
                                </Link>
                            </li>
                            <li>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/packages"
                                >
                                    الباقات
                                </Link>
                            </li>
                            <li >
                            <Link
                                className="lin-footer"
                                    target="_blank"
                                    to="https://play.google.com/store/apps/details?id=online.whitesooq"
                                    style={{ textDecoration: "none", color: "#FFFFFF", }}
                                >
                                    تحميل التطبيق
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mmaa paasa">
                        <ul className="footer-mar">
                            <li>
                                <a
                                    href="/"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    ابحث واتصل مع البائعين
                                </a>
                            </li>
                            <li>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                    to="/add-sale-ad"
                                >
                                    ابدأ بيع إعلانك الآن
                                </Link>
                            </li>
                            <li>
                                <a
                                    href="/"
                                    style={{
                                        textDecoration: "none",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    قائمة الإعلانات
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                    
                    
                </div>
                <div className="finalfooter">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12 d-flex so">
                            <div
                                style={{
                                    marginRight: "-3px",
                                    marginTop: "-6px",
                                }}
                            >
                                <img src={face} />
                            </div>
                            <div
                                style={{
                                    marginRight: "-16px",
                                    marginTop: "-6px",
                                }}
                            >
                                <img src={insta} />
                            </div>
                            <h6> : تابعنا على </h6>
                        </div>
                        <div
                            className="col-lg-4 col-md-4 col-sm-12 bo  nnnv"
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop: "9px",
                            }}
                        >
                            <Link
                                className="nawww"
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                    whiteSpace:'nowrap'
                                }}
                            >
                                {" "}
                                Design&Coding BY Nawar.Team{" "}
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 bo">
                            <h6 className="cooom" style={{ marginTop: "7px", whiteSpace:'nowrap' }}>
                                www.Whitsooq.com <span>2023</span>
                            </h6>

                            <h6 className="coppp">كل الحقوق محفوظة</h6>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default Footer;
