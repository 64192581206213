// @ts-nocheck
import { East } from "@mui/icons-material";
import {
  Box,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import classes from "./EditRoleOfOwner.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
// import { styled } from "@mui/system";
import { styled } from "@mui/material/styles";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import Snack from "components/Utilities/Snack";

const Root = styled("div")(
  ({ theme }) => `
    table {
      width: 100%;
      --border: 1px solid #A0BED0;
      border-radius: 10px;
      border-spacing: 0;
      border-collapse: separate;
      border: var(--border);
      overflow: hidden;
    }
    td {
      border: 1px solid #A0BED0;
      color: #1d3557;
      text-align: center;
      padding: 8px;
      font-size: 17px;
      font-weight: 700;
    }
    th{
      text-align:center;
      background-color: #1d3557;
      color:#fff;
      padding:8px;
    }
    `
);
const ClickIcon = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 100,
  height: 32,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(70px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#05FD94",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[800],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 28,
    height: 28,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditRoleOfOwner = (props) => {
  // const { name } = props.name;
  const { authInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [pers, setPers] = useState([]);
  const [dat, setDat] = useState({});
  const params = useParams();
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  // const [checkedStatus, setCheckedStatus] = useState(
  //   pers?.permissions?.map((row) => row.status === "allow") )
  const handleChange = async (event, index) => {
    setDat((dat[event.target.name] = event.target.checked ? "allow" : "deny"));
    try {
      // Perform API call to update the status on the server
      const res = await axios.put(
        `https://api.whitesooq.com/api/v1/roles/${params.id}`,
        { permissions: dat },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );

      // Update the state with the modified permissions
      console.log(res);
      setDat({});
    } catch (err) {
      if (err.request.status === 500 || err.request.status === 403) {
        setAlert({
          open: true,
          type: "warning",
          message: "عذراً ليس لديك صلاحية",
        });
      }
    }
  };

  useEffect(() => {
    const showperr = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/roles/${params.id}`,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.token}`,
            },
          }
        );
        console.log(response);
        setPers(response.data.role);
      } catch (Error) {
        console.log(Error);
        if (Error.request.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else if (Error?.response?.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
        } else if (Error?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: Error?.response?.data?.message,
          });
        } else if (Error?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "الخادم لا يستجيب",
          });
        }
      }
    };
    showperr();
  }, []);
  console.log(pers);
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/view-roles-page"
          >
            الأدوار
          </Link>
          / تفاصيل دور{" "}
          {pers.name === "super-admin"
            ? "مسؤول"
            : pers.name === "admin"
            ? "مشرف"
            : "مالك"}
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.BoxShowRole}
          sx={{
            padding: { xs: "16px 8px", sm: 2 },
            width: { xs: 333, sm: 650 },
            height: { xs: 570, sm: 800 },
          }}
        >
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.BoxShowRoleInside}
            sx={{ p: 1 }}
          >
            <IconButton
              onClick={() => navigate(`/view-roles-page/${params.id}`)}
            >
              <East />
            </IconButton>
            <Typography
              sx={{
                color: "#1D3557",
                fontWeight: "800",
                mx: 1,
                mt: 2,
                fontSize: "27px",
              }}
              variant="h5"
            >
              تعديل دور{" "}
              {pers.name === "super-admin"
                ? "مسؤول"
                : pers.name === "admin"
                ? "مشرف"
                : "مالك"}
            </Typography>

            <Box sx={{ mx: 1, my: 2 }} style={{ direction: "ltr" }}>
              <Root sx={{ maxWidth: "100%", width: "100%", mt: 1 }}>
                <table aria-label="custom pagination table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left", paddingLeft: "25px" }}>
                        مسموح/غير مسموح
                      </th>
                      <th>صلاحية الدور</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pers.permissions?.map((row, index) => (
                      <tr key={row.id}>
                        <td
                          style={{
                            fontWeight: 600,
                            textAlign: "left",
                            paddingLeft: "25px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <ClickIcon
                                name={row.ability}
                                sx={{ m: 1 }}
                                defaultChecked={row.status === "allow"}
                                onChange={(event) =>
                                  handleChange(event, index, row.ability)
                                }
                              />
                            }
                          />
                        </td>
                        <td
                          style={{
                            width: "40%",
                            color: row.ar_ability.includes("استعراض")
                              ? "#1d3557"
                              : "#457B9D",
                          }}
                        >
                          {row.ar_ability}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Root>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default EditRoleOfOwner;
