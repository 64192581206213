import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Form, Image } from "react-bootstrap";
import home from "./../admin-image/categ/home.svg";
import "./viewrole.css";
import Table from "react-bootstrap/Table";
import eye from "./../admin-image/roles/eye.svg";
import { AuthContext } from "store/UseAuthContext";
import Home from "./Home.svg";
import { Link } from "react-router-dom";
export const Viewroles = () => {
  const [error, setError] = useState("");
  const { authInfo } = useContext(AuthContext);
  const [allData, setAllData] = useState([]);
  const fetchPackagesHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch("https://api.whitesooq.com/api/v1/roles", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
        },
      });
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الأدوار يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      // console.log(data);
      if (data?.roles) {
        setAllData(data.roles);
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);
  useEffect(() => {
    fetchPackagesHandler();
  }, [fetchPackagesHandler]);

  return (
    <div
      className="viewroles"
      style={{ backgroundColor: "#F2F2F2", height: "100vh" }}
    >
      <div className="homing d-flex" dir="rtl">
        <Form.Label
          style={{
            display: "flex",
            direction: "rtl",
            alignItems: "flex-start",
            marginRight: "25px",
            marginTop: "16px",
          }}
        >
          <Image style={{ marginTop: "-2px" }} src={Home} />
          <h4
            style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}
          >
            الأدوار
          </h4>
        </Form.Label>
      </div>

      <Container>
        <div className="roles" dir="rtl">
          <Container>
            <h3>عرض الأدوار</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "15%", textAlign: "center" }}>
                    معرف الدور
                  </th>
                  <th style={{ width: "20%", textAlign: "center" }}>
                    نوع المستخدم
                  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {allData.map((Role) => (
                  <tr>
                    <td>{Role.id}</td>
                    <td style={{ width: "20%", textAlign: "center" }}>
                      {Role.name === "admin"
                        ? "مشرف"
                        : Role.name === "super-admin"
                        ? "مسؤول"
                        : "مالك"}
                    </td>
                    <td className="tdf">
                      <Link to={`/view-roles-page/${Role.id}`}>
                        <img src={eye} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </div>
      </Container>
    </div>
  );
};
