// @ts-nocheck
import "./Statisticsaccount.css";
import X from "./x.svg";
import yellow from "./yellow.svg";
import orange from "./orange.svg";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Container } from "@mui/system";
import { AuthContext } from "store/UseAuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Rating, Typography } from "@mui/material";
// import Change from 'components/setting account/ChangePassword'
export const Statisticsaccount = () => {
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  // console.log(authInfo);
  const [result, setResult] = useState({});
  // console.log(result);
  const date = new Date(result?.created_at);
  const token = localStorage.getItem("token");
  // const refresh = async () => {
  //     await axios
  //         .post(
  //             "https://api.whitesooq.com/api/v1/refresh-token",
  //             {},
  //             {
  //                 headers: {
  //                     accept: "application/json",
  //                     Authorization: `Bearer ${token}`,
  //                     app_key:
  //                         "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
  //                 },
  //             }
  //         )
  //         .then((response) => {
  //             ChangeAuthInfo(response.data);
  //             localStorage.setItem("token", response.data.token);
  //         })
  //         .catch((err) => {
  //             // if (err.data.message === "Unauthenticated") {
  //             //     ChangeAuthInfo({});
  //             // }
  //         });
  // };
  useEffect(() => {
    // token !== "" ? refresh() : setTimeout({}, 200);
    getAPI();
  }, []);
  const getAPI = async () => {
    try {
      const response = await axios.get(
        `https://api.whitesooq.com/api/v1/users/results`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      // console.log(response);
      setResult(response.data.user);
    } catch (err) {
      if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        navigate("/");
      }
    }
  };
  return (
    <div className="Statisticsaccount" dir="rtl">
      <Container>
        <div className="row">
          <Link to={"/"}>
            <img src={X} alt="img" />
          </Link>
          <h3>احصائيات الحساب الشخصي</h3>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 sect1">
            <Avatar
              sx={{
                ml: 1,
                width: "40px",
                height: "40px",
                p: 0,
              }}
              className="ranim"
              src={result?.full_path_image}
            />
            <h4>{result?.username}</h4>
            <h3>{result?.fullname}</h3>
            <div className="followersr d-flex">
              <h4>
                <span>{result?.followers_count}</span> متابع
              </h4>
              <Rating
                readOnly
                name="size-medium"
                defaultValue={0}
                value={result?.rating}
                icon={
                  <Typography
                    component={"img"}
                    width={30}
                    height={30}
                    src={orange}
                  />
                }
                emptyIcon={
                  <Typography
                    component={"img"}
                    width={30}
                    height={30}
                    src={yellow}
                  />
                }
              />
              <span> ({result?.rating}) </span>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 sect2">
            <div className="numeros d-flex">
              <div className="">
                <span>{result?.ads_count}</span>
                <h3>عدد إعلانات المستخدم</h3>
              </div>
              <div>
                <span>{result?.offers_count}</span>
                <h3>عدد عروض المستخدم</h3>
              </div>
              <div>
                <span>{result?.comments_count}</span>
                <h3>عدد تعليقات المستخدم</h3>
              </div>
            </div>
            <div className="numero d-flex">
              <div className="d-flex">
                <span>{result?.total_messages}</span>
                <h3>عدد نقرات المراسلة.</h3>
              </div>
              <div className="d-flex">
                <span>{result?.total_call}</span>
                <h3> عدد نقرات الاتصال. </h3>
              </div>
              <div className="d-flex">
                <span>{result?.total_whatsapp}</span>
                <h3> عدد نقرات الواتساب. </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row buildaccount d-flex">
          <h3> تم انشاء الحساب بتاريخ</h3>
          <h2>
            {result?.created_at
              ? `${date?.getFullYear()}-${date?.getMonth()}-${date?.getDate()}`
              : ""}
          </h2>
        </div>
      </Container>
    </div>
  );
};
