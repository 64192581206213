import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Box, Typography, Container } from "@mui/material";
import classes from "./PackagesDeleted.module.css";
import Grid from "@mui/material/Grid";
import nextPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-right-circle.png";
import prevPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-left-circle.png";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { Check, Close, WarningAmber } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import Snack from "components/Utilities/Snack";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PackagesDeleted = () => {
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [packages, setPackages] = useState([]);
  const { authInfo } = useContext(AuthContext);
  const [error, setError] = useState("");
  const fetchPackagesHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch(
        "https://api.whitesooq.com/api/v1/packages/trashed",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authInfo.token}`,
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الباقات يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      setAllData(data);
      // console.log(data);
      if (data.status) {
        const loadedPackages = [];
        for (const key in data.packages) {
          loadedPackages.push({
            id: data.packages[key].id,
            name: data.packages[key].name,
            type: data.packages[key].type,
            buyAdsLimit: data.packages[key].buy_ads_limit,
            buyAdsResultable: data.packages[key].buy_ads_resultable,
            buyAdsUpdateable: data.packages[key].buy_ads_updateable,
            buyAdsValidity: data.packages[key].buy_ads_validity,
            cost: data.packages[key].cost,
            hideOffer: data.packages[key].hide_offer,
            offersLimit: data.packages[key].offers_limit,
            validity: data.packages[key].validity,
            saleAdsLimit: data.packages[key].sale_ads_limit,
            saleAdsResultable: data.packages[key].sale_ads_resultable,
            saleAdsUpdateable: data.packages[key].sale_ads_updateable,
            saleAdsValidity: data.packages[key].sale_ads_validity,
            pinAble: data.packages[key].pinable,
            pinAbleValidity: data.packages[key].pinable_validity,
            offerHighlighting: data.packages[key].offer_highlighting,
            serviceDiscount: data.packages[key].service_discount,
            description: data.packages[key].description,
          });
        }
        setPackages(loadedPackages);
      }
    } catch (error) {
      setError(error.message);
    }
  }, [authInfo.token]);
  useEffect(() => {
    fetchPackagesHandler();
  }, [fetchPackagesHandler]);
  const carousel = useRef(null);
  const handleLeftClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft -= 310;
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft += 310;
  };
  const deleteHandler = (id) => {
    fetch(`https://api.whitesooq.com/api/v1/packages/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        return response.json();
      })
      .then((data) => {
        console.log(data);
        handleClose();
        if (data.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف الباقة بشكل نهائي ",
          });
          setTimeout(() => {
            navigate("/activepackages-page");
          }, 1000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "هناك مشكلة في عملية الحذف، حاول مرة أخرى",
          });
        }
      })
      .catch((error) => {
        // setError(error.message);
      });
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const recoveryHandler = (id) => {
    fetch(`https://api.whitesooq.com/api/v1/restore-package/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        return response.json();
      })
      .then((data) => {
        console.log(data);
        handleClose();
        if (data.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ استعادة الباقة بنجاح ",
          });
          setTimeout(() => {
            navigate("/activepackages-page");
          }, 1000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "هناك مشكلة في عملية الاستعادة، حاول مرة أخرى",
          });
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />
      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4
          style={{
            color: "#1D3557",
            fontSize: "18px",
            marginRight: "5px",
          }}
        >
          الباقات المحذوفة
        </h4>
      </Form.Label>
      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.PackagesTitle}
          sx={{ width: { xs: 310, sm: 620, md: 1000 } }}
        >
          {/* Start title Box */}
          <Box sx={{ textAlign: "right", p: "25px 15px" }}>
            <Typography className={classes.title} variant="h5" component="h5">
              عرض الباقات المحذوفة
            </Typography>
            <Typography
              sx={{
                fontSize: "22px",
                pt: "15px",
                pb: 4,
                fontWeight: 800,
                color: "#457b9d",
              }}
              variant="h5"
              component="h5"
            >
              عدد الباقات {allData?.count}
            </Typography>
          </Box>
          {/* End title Box */}
          {/* Start packages Box */}
          <div
            className={classes.carousel}
            ref={carousel}
            style={{ direction: "ltr" }}
          >
            {packages?.map((pack) => (
              <Grid item key={pack.id}>
                <Box
                  className={classes.boxPackages}
                  sx={{
                    height: 625,
                  }}
                >
                  <Card
                    variant="outlined"
                    className={classes.package}
                    sx={{
                      border: `2px solid ${
                        pack.type === "private" ? "#f9a703" : "#A8DADC"
                      }`,
                    }}
                  >
                    <CardContent>
                      {/*Start section different */}
                      <Typography className={classes.different}>
                        {pack.type === "private" ? "مميز" : ""}
                      </Typography>
                      {/*End section different */}
                      {/*Start section type */}
                      <Typography
                        className={classes.type}
                        sx={{
                          fontSize: 15,
                          textAlign: "right",
                          pr: 2,
                          pt: 1,
                          color: "#1d3557",
                          fontWeight: "800",
                        }}
                        gutterBottom
                      >
                        {pack.name}
                      </Typography>
                      {/*End section type */}
                      {/*Start section price */}
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.price}
                        sx={{
                          color: "#1d3557 !important",
                          textAlign: "center",
                          pl: 5,
                          my: 1,
                          fontSize: "35px !important",
                          fontWeight: "bold",
                        }}
                      >
                        {pack.id === 1 ? "مجانية" : pack.cost}
                      </Typography>
                      {/*End section price */}
                      {/*Start section line */}
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            pack.type === "private" ? "#FB8500" : "#A8DADC",
                          mb: 2,
                        }}
                      >
                        __________________________________
                      </Typography>
                      {/*End section line */}
                      {/*Start section description */}
                      <Typography
                        sx={{ textAlign: "right" }}
                        // @ts-ignore
                        variant="div"
                      >
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .صلاحية الباقة {pack.validity} يوم
                          </p>
                          {pack.validity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            {`.مدة صلاحية إعلانات بيع ${pack.saleAdsValidity} أيام `}
                          </p>
                          {pack.saleAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "2px",
                              marginBottom: 0,
                            }}
                          >
                            {` .  حد نشر   ${pack.saleAdsLimit}  إعلانات بيع  `}
                            {pack.saleAdsLimit === 0 ? (
                              <Close
                                sx={{
                                  fill: "red",
                                }}
                              />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "2px",
                              marginBottom: 0,
                            }}
                          >
                            {`.السماح بتعديل إعلانات البيع `}
                            {pack.saleAdsUpdateable === 0 ? (
                              <Close
                                sx={{
                                  fill: "red",
                                }}
                              />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .السماح بالحصول على إحصائيات إعلانات البيع
                          </p>
                          {pack.saleAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            {`.مدة صلاحية إعلانات الشراء ${pack.buyAdsValidity}  أيام`}
                          </p>
                          {pack.buyAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "2px",
                              marginBottom: 0,
                            }}
                          >
                            {`.حد نشر ${pack.buyAdsLimit} إعلانات شراء  `}
                            {pack.buyAdsLimit === 0 ? (
                              <Close
                                sx={{
                                  fill: "red",
                                }}
                              />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "2px",
                              marginBottom: 0,
                            }}
                          >
                            .السماح بتعديل إعلانات الشراء
                            {pack.buyAdsUpdateable === 0 ? (
                              <Close
                                sx={{
                                  fill: "red",
                                }}
                              />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .السماح بالحصول على إحصائيات إعلانات الشراء
                          </p>
                          {pack.buyAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .تثبيت الإعلانات
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .مدة صلاحية تثبيت الإعلان {pack.pinAbleValidity}
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .نمييز العرض
                          </p>
                          {pack.offerHighlighting === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .إخفاء قيمة العرض
                          </p>
                          {pack.hideOffer === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .حد نشر {pack.offersLimit} عروض
                          </p>
                          {pack.offersLimit === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "8px",
                              marginBottom: 0,
                            }}
                          >
                            .خصومات الخدمات
                          </p>
                          {pack.serviceDiscount === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                      </Typography>
                      {/*End section description */}
                    </CardContent>
                    {/*Start section button */}
                    <Box className={classes.BoxButtons}>
                      <button
                        className={`${classes.btnDetails} ${classes.btnRight} ${
                          pack.type === "private"
                            ? classes.btnDifference
                            : classes.btnNormal
                        }`}
                        type="submit"
                        onClick={() => recoveryHandler(pack.id)}
                      >
                        استعادة
                      </button>
                      <button
                        className={`${classes.btnDetails} ${classes.btnLeft}`}
                        type="submit"
                        onClick={handleClickOpen}
                      >
                        حذف نهائي
                      </button>
                    </Box>
                    {open && (
                      <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        sx={{ textAlign: "center" }}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <WarningAmber
                          sx={{
                            color: "#f9a703",
                            m: "0 auto",
                            width: "80px",
                            height: "80px",
                          }}
                        />
                        <DialogTitle
                          sx={{
                            textAlign: "center",
                            m: "0 auto",
                            p: "0 20px",
                            color: "#457B9D",
                            fontWeight: 700,
                          }}
                        >
                          هل أنت متأكد من حذف هذه الباقة بشكل نهائي؟
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText
                            sx={{ m: 0, p: 0 }}
                            id="alert-dialog-slide-description"
                          >
                            <Close
                              sx={{
                                color: "red",
                              }}
                            />
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ m: "0 auto" }}>
                          <button
                            style={{
                              borderRadius: "5px",
                              width: "60px",
                              height: "33px",
                              border: "1px solid #1d3557",
                              backgroundColor: "#fff",
                              color: "#1d3557",
                              marginBottom: "10px",
                              marginRight: "4px",
                            }}
                            className={classes.btnCancelDialog}
                            onClick={handleClose}
                          >
                            إلغاء
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              width: "60px",
                              height: "33px",
                              border: "none",
                              backgroundColor: "#1d3557",
                              color: "#fff",
                              marginBottom: "10px",
                              paddingRight: "4px",
                            }}
                            className={classes.btnOkDialog}
                            onClick={() => deleteHandler(pack.id)}
                          >
                            متأكد
                          </button>
                        </DialogActions>
                      </Dialog>
                    )}
                    {/*End section button */}
                  </Card>
                  {error}
                </Box>
              </Grid>
            ))}
          </div>

          <div className={classes.buttons}>
            <button onClick={handleRightClick}>
              <img src={nextPackage} alt="Scroll Left" />
            </button>
            <button onClick={handleLeftClick}>
              <img src={prevPackage} alt="Scroll Right" />
            </button>
          </div>
          {/* End packages Box */}
        </Box>
      </Container>
    </div>
  );
};
export default PackagesDeleted;
