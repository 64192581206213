// @ts-nocheck
import React, { useCallback, useEffect, useState, useContext } from "react";
import classes from "./DetailsPackage.module.css";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Snack from "components/Utilities/Snack";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
const DetailsPackage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  useEffect(() => {
    setError(null);
    const getAPI = async () => {
      try {
        const response = await fetch(
          `https://api.whitesooq.com/api/v1/packages/${id}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        const data = await response.json();
        // console.log(data);
        setData(data?.package);
      } catch (err) {
        // console.log(err);
      }
    };
    getAPI();
  }, []);
  // console.log(data);

  const submitDelete = (id) => {
    axios
      .delete(`https://api.whitesooq.com/api/v1/delete-package/${id}`, {
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
        },
      })
      .then((response) => {
        if (response.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        }
        console.log(response.data);
        return response.data;
      })
      .then((data) => {
        console.log(data);
        if (data.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف الباقة ",
          });
          setTimeout(() => {
            navigate("/packagesdeleted-page");
          }, 750);
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          open: true,
          type: "warning",
          message: "هناك مشكلة في عملية الحذف",
        });
      });
  };

  const sentence = [
    {
      field1: "تكلفة الباقة",
      field2: data.id === 1 ? "مجانية" : data.cost,
    },
    { field1: "صلاحية الباقة", field2: data ? data.validity : "" },
    {
      field1: "مدة صلاحية إعلانات البيع",
      field2: data ? data.sale_ads_validity + " أيام" : "",
    },
    {
      field1: "حد نشر إعلانات البيع",
      field2: data ? data.sale_ads_limit + " إعلانات " : "",
    },
    {
      field1: "سماحية تعديل اعلانات البيع",
      field2: data ? (data.sale_ads_updateable === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "سماحية الحصول على احصائيات إعلانات البيع",
      field2: data ? (data.sale_ads_resultable === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "تمييز العرض",
      field2: data ? (data.offer_highlighting === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "حد نشر العروض",
      field2: data ? data.offers_limit + " عروض " : "",
    },
    {
      field1: "اخفاء قيمة العرض",
      field2: data ? (data.hide_offer === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "خصومات الخدمات",
      field2: "%" + data ? data.service_discounts : "",
    },
    {
      field1: "تثبيت الإعلانات",
      field2: data ? (data.pinable === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "مدة صلاحية تثبيت الإعلان",
      field2: data ? data.pinable_validity + " أيام " : "",
    },
    {
      field1: "حد نشر إعلانات الشراء",
      field2: data ? data.buy_ads_limit + " إعلانات " : "",
    },
    {
      field1: "مدة صلاحية إعلانات الشراء",
      field2: data ? data.buy_ads_validity + " أيام " : "",
    },
    {
      field1: "سماحية تعديل اعلانات الشراء",
      field2: data ? (data.buy_ads_updateable === 0 ? "لا" : "نعم") : "",
    },
    {
      field1: "سماحية الحصول على احصائيات إعلانات الشراء",
      field2: data ? (data.buy_ads_resultable === 0 ? "لا" : "نعم") : "",
    },
  ];
  return (
    <div
      className="cat-container"
      style={{ display: "grid", backgroundColor: "#f1f1f1" }}
    >
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4
          style={{
            color: "#1D3557",
            fontSize: "18px",
            marginRight: "5px",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/activepackages-page"
          >
            الباقات النشطة
          </Link>
          / {data ? data.name : ""}
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "0px",
            marginTop: "-9px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            margin: "0 auto",
            textAlign: "right",
            backgroundColor: "#fff",
            borderRadius: "20px",
            boxShadow: "0 4px 35px 0px #00000014",
            width: { xs: 330, sm: 650 },
            height: { xs: 550, sm: 800 },
          }}
        >
          <IconButton
            sx={{ m: 1 }}
            onClick={() => navigate("/activepackages-page")}
          >
            <East />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ color: "#1D3557", fontWeight: "800", m: 1 }}
            component="h5"
          >
            عرض تفاصيل {data ? data.name : ""}
          </Typography>
          <Typography
            sx={{
              color: "#457b9d",
              fontWeight: "800",
              m: "5px 8px",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>معرف الباقة #{data ? data.id : ""}</div>
              <div style={{ marginLeft: "9px" }}>
                نوع الباقة:
                {data ? (data.type === "public" ? "عامة" : "خاصة") : ""}
              </div>
            </div>
          </Typography>
          <table className={classes.tableDetails}>
            {sentence.map((item, index) => (
              <tbody className="" key={index}>
                {index === 0 && (
                  <div
                    key={index}
                    className={`${classes.red}  ${classes.tableRow}`}
                  >
                    <div className={classes.sentence1}>{item.field1}</div>
                    <div className={classes.sentence2}>{item.field2}</div>
                  </div>
                )}
                {index !== 0 && (
                  <tr
                    key={index}
                    className={`${classes.tableRow}`}
                    style={{
                      backGroundColor: `${
                        index % 2 === 0
                      } ? "#A8DADC" : "#DEEDEE" }`,
                    }}
                  >
                    <td className={classes.sentence1}>{item.field1}</td>
                    <td className={classes.sentence2}>{item.field2}</td>
                  </tr>
                )}
              </tbody>
            ))}
          </table>
          <div className={classes.ButtonsAdminDetails}>
            <button
              onClick={() => submitDelete(data?.id)}
              className={classes.delete}
              style={{ backgroundColor: "#F1FAEE" }}
            >
              حذف
            </button>
            <Link
              // type="submit"
              to={`/editpackage-page/${data?.id}`}
              // onClick={handleDetails(id)}
              className={classes.edit}
              style={{
                color: "#fff",
                backgroundColor: "#457b9d",
                outline: "none",
                border: "none",
                padding: "5px 80px",
                margin: "0 8px",
                borderRadius: "5px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              تعديل
            </Link>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default DetailsPackage;
