// @ts-nocheck
import { Box, Container, IconButton, Typography } from "@mui/material";
import classes from "./Message.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/system";
import { Form, Image } from "react-bootstrap";
import Home from "./Home.svg";
import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";
import { East } from "@mui/icons-material";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Root = styled("div")(
  ({ theme }) => `
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td {
    border: 1px solid #457B9D;
    color: #1d3557;
    text-align: center;
    padding: 8px;
    font-size: 17px;
    font-weight: 700;
  }
  
  `
);
const Message = (props) => {
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/messages/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.data) {
          setMessage(responseData.data);
        }
      });
  }, []);
  function createData(content, value) {
    return { content, value };
  }
  const rows = [
    createData(message?.fname, "الاسم الأول"),
    createData(message?.lname, "الاسم الثاني"),
    createData(message?.phone_number, "رقم الموبايل"),
    createData(message?.content, "محتوى الرسالة"),
  ];
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [reportDeleted, setReportDeleted] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    axios
      .delete(`https://api.whitesooq.com/api/v1/messages/${id}`, {
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
        },
      })
      .then((response) => {
        if (response.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        }
        return response.data;
      })
      .then((data) => {
        if (data.status === 1) {
          setReportDeleted(true);
          setTimeout(() => {
            setOpen(false);
            navigate("/messages-page");
          }, 1750);
        }
      })
      .catch((error) => setError(error.message));
  };

  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/messages-page"
          >
            الرسائل الواردة للموقع
          </Link>
          / تفاصيل الرسالة
        </h4>
      </Form.Label>

      <Container>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginTop: "0px",
            marginRight: "-30px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            width: { xs: 350, sm: 650 },
            height: { xs: 700, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل الرسالة
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: 2,
              width: { xs: 320, sm: 560 },
              height: { xs: 620, sm: 600 },
            }}
          >
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/messages-page")}
            >
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <FormControl sx={{ mb: 2 }}>
                <Select
                  IconComponent={ExpandMore}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  autoWidth
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem onClick={handleClickOpen} sx={{ pl: 6 }}>
                    حذف
                  </MenuItem>
                </Select>
              </FormControl>
              {!reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"هل أنت متأكد من حذف هذه الرسالة"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      لا يمكن استعادة الرسالة عند الحذف
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleDelete}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"error"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"تم حذف الرسالة بنجاح"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      موافق
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{
                color: "#1d3557",
                fontWeight: 800,
                fontSize: { xs: "18px", sm: "28px" },
              }}
            >
              عرض الرسالة
            </Typography>
            <Root sx={{ maxWidth: "100%", width: "100%", mt: 1 }}>
              <table
                aria-label="custom pagination table"
                style={{ direction: "ltr" }}
              >
                <tbody>
                  {rows.map((row) => (
                    <tr key={row.content}>
                      <td style={{ fontWeight: 400 }}>{row.content}</td>
                      <td style={{ width: 120 }} align="right">
                        {row.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Root>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Message;
